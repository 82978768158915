import { useEffect, useRef, useState } from "react";
import "./styles.css";
import "../components/styles.css";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { setStep } from "../redux/rootSlice";
import { AppPaths } from "../utils/AppPaths";
import PrimaryButton from "../components/PrimaryButton";
import TextInput from "../components/TextInput";
import TextInputDropDown from "./TextInputDropDown";
import { setUser } from "../redux/OnBoardingSlice";
const StepSix = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { step } = useAppSelector((state) => state.root);
  const { user } = useAppSelector((state) => state.onBoarding);
  const [inputValue, setInputValue] = useState<Record<string, string>>({});
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Scroll to the specified element on component mount
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, []);
  const handleInputChange = (e: string, type: string) => {
    console.log("[handleInputChange]", e);

    setInputValue({
      ...inputValue,
      [type]: e,
    });
  };

  const onContinue = () => {
    dispatch(
      setUser({
        ...user,
        city: inputValue.city,
        state: inputValue.state,
        school: inputValue.school,
      })
    );
    navigate(AppPaths.ONBOARDING_SEVEN);
  };

  const options = [
    { label: "India", value: "india" },
    { label: "Usa", value: "Usa" },
    { label: "Canada", value: "Canada" },
    { label: "Africa", value: "Africa" },
    { label: "UK", value: "UK" },
  ];
  return (
    <div
      // ref={scrollRef}
      className="flex flex-1 flex-col"
      style={{ marginTop: "1vh" }}
    >
      <div className="flex item-center justify-center">
        <span className="sub-title-text-16 text-center ">
          (This will help us find you the right
          <br /> community.)
        </span>
      </div>
      <div className="mx-8 mt-1 flex flex-col flex-1 ">
        <div className="TextInputContainer">
          <span className="">
            <div className="input-text-14-bold ">State</div>
            {/* <TextInputDropDown
              placeholder="Select your state"
              containerStyles="px-3 py-3 "
              onSelect={(val: string) => {
                handleInputChange(val, "state");
              }}
              options={[
                { label: "India", value: "india" },
                { label: "Usa", value: "Usa" },
                { label: "Canada", value: "Canada" },
                { label: "Africa", value: "Africa" },
                { label: "UK", value: "UK" },
              ]}
              rightIcon={
                <img
                  alt=""
                  className="h-5  w-5"
                  src={require("../assets/down-arrow.png")}
                />
              }
            /> */}
            <div className="TextInputDropDown   flex flex-1 flex-row items-cente px-3 py-3 w-full">
              <select
                value={inputValue?.state || ""}
                className={`flex flex-1  decoration-none outline-none bg-white selectOptions ${
                  inputValue?.state && "font-black"
                }`}
                onChange={(e) => {
                  const item = options?.find(
                    (option) => option.value === e.target.value
                  );
                  if (item) {
                    handleInputChange(item.value, "state");
                  }
                }}
              >
                <option value="" disabled hidden>
                  Select your state
                </option>
                {options?.map((option) => (
                  <option className="flex flex-1 outline-none">
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </span>
          <span className="">
            <div className="input-text-14-bold mt-4">City</div>
            <TextInput
              placeholder="Enter your city"
              value={inputValue.city}
              onChange={(val) => handleInputChange(val.target.value, "city")}
              containerStyles="px-3 py-3"
              textInputStyles={{
                flex: 1,
                display: "flex",
              }}
            />
          </span>
          <span className="">
            <div className="input-text-14-bold mt-4">School</div>
            <TextInput
              placeholder="Enter the name of your school"
              value={inputValue.school}
              onChange={(val) => handleInputChange(val.target.value, "school")}
              containerStyles="px-3 py-3 "
              textInputStyles={{
                flex: 1,
                display: "flex",
              }}
              // rows={10}
            />
          </span>
        </div>
      </div>
      <div className="pt-5 mb-5 ">
        <PrimaryButton
          text="CONTINUE"
          onClick={onContinue}
          containerStyles="py-3 px-8 mx-5"
        />
      </div>
    </div>
  );
};

export default StepSix;
