import { useEffect, useState } from "react";
import ApiService from "../../api/ApiServices";
import moment from "moment";

const MyJourney = () => {
  const [journeyDetails, setJourneyDetails] = useState<any>(null);

  const getJourneyData = async () => {
    const response = await ApiService.getMyJourney();
    console.log('res', response?.data)
    setJourneyDetails(response?.data);
  }

  useEffect(() => {
    getJourneyData();
  }, [])
  const renderTopGradient = () => (
    <svg
      // width="375"
      // height="123"
      viewBox="0 0 365 143"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: "absolute",
        top: -32,
        zIndex: -10,
      }}
    >
      <path
        d="M-66 43L44.741 18.2962C142.95 -3.6119 244.864 -2.80936 342.716 20.6427L436 43"
        stroke="url(#paint0_linear_1102_5554)"
        stroke-width="133"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1102_5554"
          x1="16"
          y1="43"
          x2="363"
          y2="43"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF9EEB" />
          <stop offset="0.145833" stop-color="#FF9728" stop-opacity="0.74" />
          <stop offset="0.302083" stop-color="#FFD203" stop-opacity="0.61" />
          <stop offset="0.510417" stop-color="#F1F232" stop-opacity="0.48" />
          <stop offset="0.65625" stop-color="#06AE56" stop-opacity="0.67" />
          <stop offset="0.859375" stop-color="#22A094" stop-opacity="0.77" />
          <stop offset="0.994236" stop-color="#91A8EC" />
        </linearGradient>
      </defs>
    </svg>
  );
  const renderHeaderImages = () => {
    return (
      <div className="flex z-10 mb-4 pt-1">
        <div className="flex flex-row flex-1 pl-5 items-center">
          <img
            src={require("../../assets/header_jem.png")}
            alt=""
            className="header-jem"
          />
          <div className="inter-text-16-extrabold px-3">0&nbsp;pts</div>
        </div>
        <img
          src={require("../../assets/u4uOnb.png")}
          alt=""
          className="header-u4u "
        />
        <div className="flex flex-row flex-1 justify-end pr-5 items-center">
          <img
            src={require("../../assets/header_star.png")}
            alt=""
            className="header-star mr-3 "
          />
          <div className="inter-text-16-extrabold">0&nbsp;pts</div>
        </div>
      </div>
    );
  };
  const renderSvg = () => {
    return (
      <div className="flex flex-col items-center relative">
        <div className="circle-icon absolute z-10 py-4 px-5 flex">
          <img
            alt=""
            className="h-8 w-7"
            src={require("../../assets/U-logo.png")}
          />
        </div>
        <div className="relative flex flex-row -right-1">
          <div className="relative">
            <div className="inter-32-bold absolute right-8 top-11 font-white">
              {journeyDetails?.pillers[0]?.points}
            </div>
            <div className="inter-13-bold absolute right-8 bottom-6 font-white">
            {journeyDetails?.pillers[0]?.piller}
            </div>

            <svg
              width="130"
              height="130"
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              // className="mr-1 mb-1"
            >
              <path
                d="M6.18056 94.5C2.76714 94.5 -0.0210419 91.7299 0.202042 88.3238C0.87645 78.0269 3.23386 67.8956 7.19339 58.3364C11.9425 46.8711 18.9033 36.4535 27.6784 27.6784C36.4536 18.9033 46.8712 11.9425 58.3364 7.19338C67.8956 3.23386 78.0269 0.876449 88.3238 0.20204C91.7299 -0.0210491 94.5 2.76713 94.5 6.18056L94.5 88.3194C94.5 91.7329 91.7329 94.5 88.3194 94.5H6.18056Z"
                fill="#FF9728"
              />
            </svg>
          </div>

          <div className="relative">
            <div className="inter-32-bold absolute left-8 top-11 font-white">
            {journeyDetails?.pillers[2]?.points}
            </div>
            <div className="inter-13-bold absolute right-8 bottom-6 font-white">
            {journeyDetails?.pillers[2]?.piller}
            </div>
            <svg
              width="130"
              height="130"
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M87.8194 94.5C91.2329 94.5 94.0209 91.7299 93.799 88.3237C93.1282 78.0268 90.7832 67.8955 86.8447 58.3364C82.1207 46.8711 75.1967 36.4535 66.468 27.6784C57.7393 18.9033 47.3768 11.9425 35.9722 7.19338C26.4738 3.23806 16.4073 0.881451 6.17614 0.204191C2.77016 -0.0212689 -7.62939e-06 2.76713 -7.62939e-06 6.18056L0 88.3194C0 91.7329 2.76713 94.5 6.18056 94.5H87.8194Z"
                fill="#629EF8"
              />
            </svg>
          </div>
        </div>
        <div className="relative flex flex-row -right-1">
          <div className="relative">
            <div className="inter-13-bold absolute right-10 top-4 font-white">
            {journeyDetails?.pillers[3]?.piller}
            </div>
            <div className="inter-32-bold absolute right-10 top-8 font-white">
            {journeyDetails?.pillers[3]?.points}
            </div>
            <svg
              width="130"
              height="130"
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              // className="mr-1"
            >
              <path
                d="M6.18056 0C2.76714 0 -0.0212708 2.77017 0.204193 6.17615C0.881454 16.4073 3.23805 26.4738 7.19339 35.9722C11.9425 47.3769 18.9033 57.7393 27.6784 66.468C36.4536 75.1967 46.8712 82.1207 58.3364 86.8447C67.8955 90.7832 78.0269 93.1282 88.3237 93.799C91.7299 94.0209 94.5 91.2329 94.5 87.8194L94.5 6.18056C94.5 2.76713 91.7329 0 88.3194 0H6.18056Z"
                fill="#CF91EC"
              />
            </svg>
          </div>

          <div className="relative">
            <div className="inter-13-bold absolute right-5 top-4 font-white">
            {journeyDetails?.pillers[1]?.piller}
            </div>
            <div className="inter-32-bold absolute left-8 top-8 font-white">
            {journeyDetails?.pillers[1]?.points}
            </div>
            <svg
              width="130"
              height="130"
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M87.8194 0C91.2329 0 94.0212 2.77014 93.7969 6.17619C93.1232 16.4074 90.7791 26.4738 86.8447 35.9722C82.1207 47.3769 75.1967 57.7393 66.468 66.468C57.7393 75.1967 47.3768 82.1207 35.9722 86.8447C26.4738 90.7791 16.4074 93.1232 6.17619 93.7969C2.77014 94.0212 -7.62939e-06 91.2329 -7.62939e-06 87.8194L0 6.18056C0 2.76713 2.76713 0 6.18056 0H87.8194Z"
                fill="#4DE19A"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="flex flex-col relative pb-20">
      {renderTopGradient()}
      {renderHeaderImages()}
      <div className="inter-20-bold text-center">My Journey</div>
      {renderSvg()}
      <div className="flex flex-row px-3">
        <div className="inter-16-bold flex flex-1">Challenges</div>
        <div className="flex flex-1 flex-row">
          <div className="inter-16-bold flex mr-3">Available Pts</div>
          <div className="inter-16-bold flex">UR Pts</div>
        </div>
      </div>

      <div className="">
        {journeyDetails?.challenges?.map((item: any, index: any) => {
          return (<div className=" my-journey-card mx-3 flex flex-row px-3  py-1 light-purple-bg mt-2">
          <div className="flex flex-1 flex-col">
            <div className="inter-text-14-semibold underline underline-offset-2">
              {item?.title}
            </div>
            <div className="inter-regular-12">{moment(item?.createdAt).format('MMMM DD, YYYY')}</div>
          </div>
          <div className="flex flex-1">
            <div className="flex flex-1 items-center justify-center inter-text-14-semibold">
              {item?.availablePoints}
            </div>
            <div className="flex flex-1 items-center justify-center inter-text-14-semibold">
              {item?.urPoints || '-'}
            </div>
          </div>
        </div>)
        })}
      </div>
    </div>
  );
};

export default MyJourney;
