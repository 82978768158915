import {
  AnxiousImages,
  InBetweenImage,
  colorsBg,
  difficultImage,
  happyImages,
} from "../../constants/reactionImages";
import { setSelctedImoji } from "../../redux/homeScreenSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { useNavigate } from "react-router-dom";
const EmojiModal = ({
  visible = false,
  setVisible,
}: {
  visible: boolean;
  setVisible: (e: boolean) => void;
}) => {
  const navigate = useNavigate();
  const { selectedImoji } = useAppSelector((state) => state.home);
  const dispatch = useAppDispatch();
  const handleBackdropClick = (e: any) => {
    if (e.target.classList.contains("ModalOverlay")) {
      setVisible(false);
    }
  };

  const renderList = (images: any, title: any, color: string) => (
    <>
      <span className="inter-14-bold  flex flex-row  flex-1 mt-3 mb-1">
        {title}
      </span>
      <div className="grid grid-cols-5 grid-rows-2 gap-1">
        {images?.map((img: any) => (
          <div
            onClick={(e) => {
              dispatch(setSelctedImoji(img));
              setVisible(false);
            }}
            className={`emoji-cont flex flex-col items-center ${
              selectedImoji?.[0] === img?.[0] && "emoji-selected"
            }`}
            style={{ backgroundColor: color }}
          >
            <img src={img?.[1]} alt="" className="emoji" />
            <div
              className="inter-text-9-semibold text-center flex flex-row items-center  flex-wrap pt-2"
              // style={{ whiteSpace: "pre-wrap", width: 50 }}
            >
              {img?.[0]}
            </div>
          </div>
        ))}
      </div>
    </>
  );

  return (
    <div className="relative z-40">
      {/* <button className="Button pinkBtn" onClick={handleModalToggle}>
        <div className="ButtonText pinkBtnText">Open modal</div>
      </button> */}
      {visible && (
        <div className="ModalOverlay " onClick={handleBackdropClick}>
          <div className="modal-radius px-3 bg-white">
            <div className="pt-3">
              {/* <span className="CloseModalButton" onClick={handleModalToggle}>
                &times;
              </span> */}
              <span className="inter-18-bold flex flex-row items-center flex-1 justify-center">
                How are you feeling?
              </span>
            </div>
            <div className="emoji-modal pb-5 overflow-y-scroll">
              {renderList(Object.entries(happyImages), "Good", colorsBg.Happy)}
              {renderList(
                Object.entries(difficultImage),
                "Difficult",
                colorsBg.difficult
              )}
              {renderList(
                Object.entries(InBetweenImage),
                "In-Between",
                colorsBg.InBetween
              )}
              {renderList(
                Object.entries(AnxiousImages),
                "Anxious",
                colorsBg.Anxious
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmojiModal;
