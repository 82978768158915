import {
  create,
  CancelToken,
  ApisauceInstance,
  ApiErrorResponse,
} from "apisauce";
// import { getCredentials } from "../services/Auth0Service";
import { AccessToken, ApiRequestType, ApiUrl, Method } from "../types";
import secureLocalStorage from "react-secure-storage";

export const baseURL = "https://api-dev.u4u.pw";
const apiMethod = async <T>(
  method: Method,
  url: ApiUrl,
  data: T | null,
  cancelToken = undefined
): Promise<T> => {
  const api: ApisauceInstance = create({
    baseURL,
    cancelToken: cancelToken || undefined,
    headers: { Accept: "application/json" },
  });

  let accessToken: AccessToken = undefined;

  try {
    let credentials = secureLocalStorage.getItem("user");
    if (credentials && typeof credentials === "string") {
      accessToken = credentials;
    }
  } catch (e) {
    accessToken = undefined;
  }

  return new Promise((resolve, reject) => {
    if (accessToken) {
      api.setHeader("Authorization", "Bearer " + accessToken);
    }

    const requestMethod = method?.toLowerCase() as Method;

    api?.[requestMethod](url, data)
      .then(async (res: any) => {
        if (!res.ok) {
          if (res.problem === "CANCEL_ERROR") {
            // eslint-disable-next-line
            return reject("CANCEL_ERROR");
          }
          return reject(res.data);
        }
        return resolve(res.data as T);
      })
      .catch(reject);
  });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  post: <T>(url: ApiUrl, data?: any, cancelToken?: any) =>
    apiMethod<T>(ApiRequestType.POST, url, data, cancelToken),
  get: <T>(url: ApiUrl) => apiMethod<T>(ApiRequestType.GET, url, null),
  put: <T>(url: ApiUrl, data: any) =>
    apiMethod<T>(ApiRequestType.PUT, url, data),
  patch: <T>(url: ApiUrl, data: any) =>
    apiMethod<T>(ApiRequestType.PATCH, url, data),
  delete: <T>(url: ApiUrl) => apiMethod<T>(ApiRequestType.DELETE, url, null),
};
