import { useEffect, useState } from "react";
import PrimaryButton from "../../../components/PrimaryButton";
import { useNavigate } from "react-router-dom";
import Table from "../../../components/Table";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import AgeModal from "../../../components/AgeModal";
import { setUser } from "../../../redux/OnBoardingSlice";
import ApiService from "../../../api/ApiServices";
import toast from "react-hot-toast";

const EditGrade = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { step } = useAppSelector((state) => state.root);
  const { user } = useAppSelector((state) => state.onBoarding);
  const [isModalOpen, setModalOpen] = useState(false);
  const userData = useAppSelector<any>(state => state.root.userData);
  const grade = userData?.questions['What grade are you in?']
  const TabelData = [
    "6th Grade",
    "7th Grade",
    "8th Grade",
    "9th Grade / Freshman",
    "10th Grade / Sophomore",
    "11th Grade / Junior",
    "12th Grade / Senior",
  ];

  useEffect(() => {
    if (grade) {
      const selectedGradeIndex = TabelData.indexOf(grade);
      if (selectedGradeIndex !== -1) {
        dispatch(setUser({ ...user, step5: grade }));
      }
    }
  }, [grade]);

  const onContinue = async () => {
    try {
      const response = await ApiService.updateUserDetails({
        grade: user?.step5,
      });
      if (response?.status) {
        toast.success(response?.message)
      }
    } catch (error) {
      console.error("Error while updating username:", error);
    }
  }

  return (
    <div className="flex flex-1 flex-col relative pb-20 mx-1 pt-3 mb-5">
      {/* <AgeModal visible={isModalOpen} setVisible={setModalOpen} /> */}
      <div className="flex flex-row mx-3 items-center">
        <div>
          <img
            onClick={() => {
              navigate(-1);
            }}
            alt=""
            className="home-frag-pic-backbtn mr-2"
            src={require("../../../assets/arrow-left.png")}
          />
        </div>
        <div className="btn-onb2-text flex flex-1 items-center justify-center">
          Edit Grade
        </div>
      </div>
      <div className="flex flex-col flex-1 mx-3 mt-3 overflow-y-auto mb-3">
        <div className="flex flex-col items-center mb-6">
          <div className="title-text-16">
            What grade are you in at school right now?
          </div>
          <div className="sub-title-text">
            (If it’s summer, tell us your next grade)
          </div>
        </div>
        <div className="mt-3 flex flex-col flex-1 mx-6">
          <Table
            data={TabelData}
            onSelect={(index: number) => {
              dispatch(setUser({ ...user, step5: TabelData[index] }));
              if (index <= 1) {
                // setModalOpen(true);
              } else {
                // onContinue();
              }
            }}
            selected={user?.step5 ? TabelData.indexOf(user?.step5) : -1}
          />
        </div>
      </div>
      <PrimaryButton
        onClick={onContinue}
        text="SAVE"
        containerStyles="py-2 mx-5"
        textStyle="inter-16-bold"
      />
    </div>
  );
};

export default EditGrade;
