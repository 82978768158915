import PrimaryButton from "../PrimaryButton";
import "../../container/styles.css";
import { useNavigate } from "react-router-dom";

const HomeScrollQuizFragment = ({
  onClick,
  btnClick,
  imgStyle = null,
  contStyle = null,
}: {
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  btnClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  imgStyle?: any;
  contStyle?: any;
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={`flex flex-col flex-1 home-frag-cont mr-3    ${
        contStyle && contStyle
      }
    }`}
    >
      <div onClick={onClick}>
        <img
          alt=""
          className={`home-frag-img ${imgStyle}`}
          src={require("../../assets/homeFragQuiz.png")}
        />
      </div>
      <div className="flex flex-row flex-1 items-center px-10">
        <PrimaryButton
          text="TAKE 2 MIN QUIZ"
          onClick={btnClick}
          containerStyles="px-1 py-1 flex flex-1 my-2"
          textStyle="inter-12-bold"
        />
      </div>
    </div>
  );
};

export default HomeScrollQuizFragment;
