import { useState } from "react";
import "./styles.css";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { setStep } from "../redux/rootSlice";
import { AppPaths } from "../utils/AppPaths";
import PrimaryButton from "./PrimaryButton";
import TextInput from "./TextInput";
import { setUser } from "../redux/OnBoardingSlice";

const StepSeven = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { step } = useAppSelector((state) => state.root);
  const { user } = useAppSelector((state) => state.onBoarding);
  const [inputValue, setInputValue] = useState<Record<string, string>>({});

  const handleInputChange = (e: string, type: string) => {
    setInputValue({
      ...inputValue,
      [type]: e,
    });
  };

  const onContinue = () => {
    dispatch(
      setUser({
        ...user,
        email: inputValue.email,
        instagramId: inputValue.instagramId,
      })
    );
    if (step === 8) {
      navigate(AppPaths.EMAILVERIFICARION);
      return;
    }
    navigate(AppPaths.ONBOARDING_EIGHT);
  };
  return (
    <div className="flex flex-1 flex-col ">
      <div className="mx-8 flex flex-col flex-1 ">
        <div className="flex flex-row justify-center py-2">
          <img alt="" src={require("../assets/ob7hi.png")} className="ob7hi" />
        </div>
        <div className="TextInputContainer mt-2">
          <span className="">
            <span className="flex flex-row items-center">
              <div className="input-text-14-bold font-bold">Email:&ensp;</div>
              <span className="sub-title-text">
                (required for verification)
              </span>
            </span>
            <TextInput
              placeholder="Enter your email"
              value={inputValue.email}
              onChange={(val) => {
                handleInputChange(val.target.value, "email");
              }}
              containerStyles="px-3 py-3"
              textInputStyles={{
                flex: 1,
                display: "flex",
              }}
            />
          </span>
          <span className="">
            <span className="flex flex-row items-center mt-4">
              <div className="input-text-14-bold font-bold">
                Instagram:&ensp;
              </div>
              <span className="sub-title-text">(for periodic updates)</span>
            </span>
            <TextInput
              placeholder="@your_IG_username"
              value={inputValue.instagramId}
              onChange={(val) => {
                handleInputChange(val.target.value, "instagramId");
              }}
              containerStyles="px-3 py-3"
              textInputStyles={{
                flex: 1,
                display: "flex",
              }}
            />
          </span>
        </div>
      </div>
      <div className="pt-10 mb-5 ">
        <PrimaryButton
          text="CONTINUE"
          onClick={onContinue}
          containerStyles="py-3 px-8 mx-5"
        />
      </div>
    </div>
  );
};

export default StepSeven;
