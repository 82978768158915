import { Outlet } from "react-router-dom";
import BottomTabs from "../../components/dashboard/BottomTabs";
import { AppPaths } from "../../utils/AppPaths";
import { setActiveTab, tabData } from "../../redux/rootSlice";

const Dashboard = () => {

  return (
    <div className="flex flex-col h-svh">
      <Outlet />
      <BottomTabs tabs={tabData} />
    </div>
  );
};

export default Dashboard;
