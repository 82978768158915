import React, { useEffect, useState } from "react";
import "./styles.css"; // Import your styling file

const Dropdown = ({
  options,
  onSelect,
  placeholder = "",
  defaultValue
}: {
  options?: Array<{ value: string; label: string }>;
  // onSelect?: (val: { value: string; label: string }) => void | undefined;
  onSelect?: (val: { value: string; label: string }) => void;
  placeholder?: string;
  defaultValue?: any,
}) => {
  const [selectedOption, setSelectedOption] = useState<{
    value: string;
    label: string;
  } | null>(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleOptionClick = (option: { value: string; label: string }) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
    if (option) {
      onSelect?.(option);
    }
  };

  useEffect(() => {
    setSelectedOption({ label: defaultValue, value: defaultValue}); // Update selected option if pre-selected value changes
  }, [defaultValue]);

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  return (
    <div className={`DropdownContainer z-30 ${isDropdownOpen ? "open" : ""}`}>
      {/* <div className="DropdownHeader py-2 px-3" onClick={toggleDropdown}>
        <div
          className={`flex flex-1 sub-title-text-16 ${
            selectedOption && "font-black"
          }`}
        >
          {selectedOption ? selectedOption.label : placeholder}
        </div>
        <img
          alt=""
          className="h-5  w-5"
          src={require("../assets/down-arrow.png")}
        />
      </div> */}
      {/* {isDropdownOpen && (
        <div className="DropdownOptions">
          {options?.map((option) => (
            <div
              key={option.value}
              className="DropdownOption"
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )} */}
      <div className="DropdownHeader selectOptions py-2 px-3 pr-4">
        <select
          className={`flex flex-1  decoration-none outline-none bg-white selectOptions ${
            selectedOption?.label && "font-black"
          }`}
          value={selectedOption?.label || ""}
          // onClick={() => handleOptionClick(option)}
          onChange={(e) => {
            const item = options?.find(
              (option) => option.value === e.target.value
            );
            if (item) {
              handleOptionClick(item);
            }
          }}
        >
          <option value="" disabled hidden>
            {placeholder}
          </option>
          {options?.map((option) => (
            <option className="selectOptions">{option.label}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Dropdown;
