import { useState } from "react";
import "./styles.css";
import TextInput from "../components/TextInput";
import PrimaryButton from "../components/PrimaryButton";
import { useAppDispatch } from "../redux/store";
import { setLogin } from "../redux/rootSlice";
import ApiService from "../api/ApiServices";
import secureLocalStorage from "react-secure-storage";
import toast from "react-hot-toast";

const LoginScreen = () => {
  const [inputValue, setInputValue] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [isChecked, setChecked] = useState(false);
  const [isPassword, setIsPassword] = useState(true);
  const dispatch = useAppDispatch();
  const handleInputChange = (e: string, type: string) => {
    setInputValue({
      ...inputValue,
      [type]: e,
    });
  };
  const renderDoorGradient = () => {
    return (
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 351 781"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="100%"
          height="109%"
          rx="7"
          fill="url(#paint0_linear_1140_1879)"
          fill-opacity="0.8"
          // stroke="black"
          // stroke-width="2"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1140_1879"
            x1="21.1686"
            y1="299.546"
            x2="274.322"
            y2="299.546"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.119792" stop-color="#FB6ADD" />
            <stop offset="0.260417" stop-color="#FF9D6C" />
            <stop offset="0.416667" stop-color="#FFDC64" />
            <stop offset="0.578125" stop-color="#42C17B" />
            <stop offset="0.75" stop-color="#3AC0E1" />
            <stop offset="0.963542" stop-color="#FC87E4" />
          </linearGradient>
        </defs>
      </svg>
    );
  };

  const handleLogin = async () => {
    try {
      setLoading(true);
      if (inputValue?.username && inputValue?.password) {
        const response = await ApiService.login({
          email: inputValue.username,
          password: inputValue.password,
        });
        console.log("[res]", response.data);
        if (response.status) {
          secureLocalStorage.setItem("user", response.data);
          dispatch(setLogin(response.data));
        } else {
          toast.error(response.message)
        }
      } else {
        // console.log("handleLogin", inputValue);
        // alert(res)
      }
    } catch (error: any) {
      console.log("[error]", error);
      alert(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-1 flex-col h-dvh  py-3 px-3 ">
        <div
          className="DoorFrame pink-bg relative px-7 py-7 my-2 flex flex-1 "
          style={{ backgroundColor: "#FB6ADD" }}
        >
          {renderDoorGradient()}
          <div className="DoorFrame blue-bg relative flex flex-1 flex-col ">
            <div className="boorlabel-cont h-2 z-30 flex justify-center absolute ">
              <img
                alt=""
                src={require("../assets/doorlabel.png")}
                className="boorlabel z-20"
              />
            </div>
            <div className="DoorTop flex flex-row  px-7 p-7 pb-2">
              <div className="boxDesign flex flex-1 mr-12 "></div>
              <div className="boxDesign flex flex-1"></div>
            </div>
            <div className="flex flex-row justify-end relative  ">
              <img
                alt=""
                className="boorhandle"
                src={require("../assets/door-handle.png")}
              />
              <div className="absolute  top-12 right-6  left-6">
                <TextInput
                  placeholder="Username"
                  value={inputValue.username}
                  containerStyles="px-3 py-3 mt-3"
                  onChange={(val) =>
                    handleInputChange(val.target.value, "username")
                  }
                />
                <TextInput
                  placeholder="Password"
                  value={inputValue.password}
                  textStyle="px-2  "
                  containerStyles="py-3 mt-3"
                  onChange={(val) =>
                    handleInputChange(val.target.value, "password")
                  }
                  secure={isPassword}
                  rightIcon={
                    <img
                      alt=""
                      className="Img-24 absolute right-1"
                      src={!isPassword ? require("../assets/eye_open.png") : require("../assets/eye_close.png")}
                      onClick={() => {
                        setIsPassword(!isPassword);
                      }}
                    />
                  }
                />

                <div className="btn-onb4-text text-center underline underline-offset-3 mt-2">
                  Forgot password
                </div>

                <PrimaryButton
                  disable={
                    !inputValue?.username || !inputValue.password || loading
                  }
                  text={"SUBMIT"}
                  onClick={handleLogin}
                  containerStyles="py-2 mt-3"
                  loading={loading}
                  textStyle="primary-btn-text"
                />

                <label className={`checkbox-container pt-2`}>
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={() => {
                      setChecked(!isChecked);
                    }}
                  />
                  <span
                    className={`checkmark ${isChecked && `checkmark-checked`}`}
                  ></span>
                  <div className="btn-onb2-text-1 text-center">Remember me</div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginScreen;
