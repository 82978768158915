import "./styles.css";
import SecondaryButton from "../components/SecondaryButton";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { setStep } from "../redux/rootSlice";
import { AppPaths } from "../utils/AppPaths";
import PrimaryButton from "../components/PrimaryButton";
import { setUser } from "../redux/OnBoardingSlice";
import SecondaryButtonSmall from "./SecondaryButtonSmall";

const StepFour = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { step } = useAppSelector((state) => state.root);
  const { user } = useAppSelector((state) => state.onBoarding);
  // const [selectedItem, setSelectedItem] = useState([]);

  const handleSelect = (data: string) => {
    if (data) {
      if (user?.step4 && user?.step4.includes(data)) {
        const filterButton = user?.step4.filter(
          (selected) => selected !== data
        );
        dispatch(setUser({ step4: filterButton }));
      } else if (user?.step4) {
        dispatch(setUser({ step4: [...user?.step4, data] }));
      } else {
        dispatch(setUser({ step4: [data] }));
      }
    }
  };

  const onContinue = () => {
    navigate(AppPaths.ONBOARDING_FIVE);
  };

  const buttons: Array<string> = [
    "New friends",
    "Relationship skills",
    "Activism",
    "Future career boost",
    "Cultural activities",
    "Be happier",
    "Have fun",
    "Mental health support",
    "College boost",
    "Community",
    "Leadership skills",
    "General support",
  ];

  return (
    <div className="flex flex-1 flex-col pt-4 ">
      <div className="flex item-center justify-center">
        <span className="sub-title-text-16 mb-3">
          (Select as many as you like)
        </span>
      </div>

      <div className="mx-5 flex flex-col flex-1 ">
        <div className="grid-container">
          {buttons?.map((button: string, index) => (
            <SecondaryButtonSmall
              onClick={() => handleSelect(button)}
              text={button}
              icon={
                user?.step4?.includes(button) ? (
                  <img
                    alt=""
                    className="h-4 w-3.5 "
                    src={require("../assets/heart-light.png")}
                  />
                ) : null
              }
              iconStyles="left-3"
              containerStyles={`py-2 mt-3  ${
                user?.step4?.includes(button) && "secondary-btn-active "
              }
              ${index === 0 && "W40"}
              ${index === 1 && "W60"}
              ${index === 2 && "W30"}
              ${index === 3 && "W65"}
              ${index === 4 && "W65"}
              ${index === 5 && "W30"}
              ${index === 6 && "W30"}
              ${index === 7 && "W65"}
              ${index === 8 && "W40"}
              ${index === 9 && "W60"}
              ${index === 10 && "W50"}
              ${index === 11 && "W50"}
              
              `}
              textStyle={`btn-onb4-text text-end ${
                user?.step4?.includes(button) && "secondary-btn-text-active "
              } `}
              selected={user?.step4?.includes(button)}
            />
          ))}
          <div
            className={`relative secondary-btn-input   py-2 px-3 overflow-hidden mt-4 flex flex-1`}
          >
            <div className={`btn-onb4-text`}>Other:&nbsp;&nbsp;</div>
            <input
              type="text"
              className={`btn-onb4-text background-color-white outline-none border-b-2 flex flex-1 border-bottom`}
            />
          </div>
        </div>
      </div>

      <div className="pt-5 mb-5 ">
        <PrimaryButton
          text="CONTINUE"
          onClick={onContinue}
          containerStyles="py-3 px-8 mx-5"
        />
      </div>
    </div>
  );
};

export default StepFour;
