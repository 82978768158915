import { useState } from "react";
import TextInput from "../../../components/TextInput";
import PrimaryButton from "../../../components/PrimaryButton";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast'
import ApiService from "../../../api/ApiServices";
import { useAppDispatch } from "../../../redux/store";
import { setUserData } from "../../../redux/rootSlice";

const EditPassword = () => {
  const dispatch = useAppDispatch();
  const [currentInputValue, setCurrentInputValue] = useState("");
  const [confirmInputValue, setConfirmInputValue] = useState("");
  const [newInputValue, setNewInputValue] = useState("");
  const navigate = useNavigate();
  const [isCurrentPassword, setIsCurrentPassword] = useState(true);
  const [isConfirmPassword, setIsConfirmPassword] = useState(true);
  const [isNewPassword, setIsNewPassword] = useState(true);
  const handleCurrentInputChange = (e: any) => {
    setCurrentInputValue(e.target.value);
  };

  const handleConfirmInputChange = (e: any) => {
    setConfirmInputValue(e.target.value);
  };

  const handleNewInputChange = (e: any) => {
    setNewInputValue(e.target.value);
  };

  const onContinue = async () => {
    try {
      const response = await ApiService.updateUserPassword({
        old_password: currentInputValue,
        new_password: newInputValue
      });
      if (response?.status) {
        toast.success(response?.message)
        dispatch(setUserData(response.data.user.password));
      } else {
        toast.error(response?.message)
      }
    } catch (error) {
      console.error("Error while updating password", error);
    }
  }
  return (
    <div className="flex flex-1 flex-col relative pb-20 mx-1 pt-3 mb-5">
      <div className="flex flex-row mx-3 items-center">
        <div>
          <img
            onClick={() => {
              navigate(-1);
            }}
            alt=""
            className="home-frag-pic-backbtn mr-2"
            src={require("../../../assets/arrow-left.png")}
          />
        </div>
        <div className="btn-onb2-text flex flex-1 items-center justify-center">
          Edit Password
        </div>
      </div>
      <div className="flex flex-col flex-1 mx-3 overflow-y-auto mb-3">
        <span className="">
          <div className="input-text-14-bold mt-4">Current Password</div>
          <TextInput
            placeholder="Enter your current password"
            value={currentInputValue}
            onChange={handleCurrentInputChange}
            secure={isCurrentPassword}
            containerStyles="px-3 py-3 flex flex-1"
            rightIcon={
              <img
                alt=""
                className="Img-24 absolute right-6"
                src={!isCurrentPassword ? require("../../../assets/eye_open.png") : require("../../../assets/eye_close.png")}
                onClick={() => {
                  setIsCurrentPassword(!isCurrentPassword);
                }}
              />
            }
            textInputStyles={{ width: '85%' }}
          />
        </span>

        <span className="">
          <div className="input-text-14-bold mt-4">New Password</div>
          <TextInput
            placeholder="Enter your new password"
            value={newInputValue}
            onChange={handleNewInputChange}
            secure={isNewPassword}
            containerStyles="px-3 py-3 flex flex-1"
            rightIcon={
              <img
                alt=""
                className="Img-24 absolute right-6"
                src={!isNewPassword ? require("../../../assets/eye_open.png") : require("../../../assets/eye_close.png")}
                onClick={() => {
                  setIsNewPassword(!isNewPassword);
                }}
              />
            }
            textInputStyles={{ width: '85%' }}
          />
        </span>

        {/* <div className="pt-10">
          <div className="inter-16-bold">Password must:</div>
          <div className="sub-title-text-16 pt-1">
            Be atleast 8 characters in length
          </div>
          <div className="sub-title-text-16 pt-1">
            Contain an uppercase letter
          </div>
          <div className="sub-title-text-16 pt-1">
            Contain a lowercase letter
          </div>
          <div className="sub-title-text-16 pt-1">Contain a digit (0-9)</div>
          <div className="sub-title-text-16 pt-1">Not include spaces</div>
        </div> */}
        <div className="pt-5">
          <div className="inter-regular-14 ">Password must include:</div>
          <ul style={{ listStyleType: "disc" }} className="mx-4">
            <li className="inter-regular-14  pt-1">
              one special character *!#$%
            </li>
            <li className="inter-regular-14  pt-1">one number</li>
            <li className="inter-regular-14  pt-1">one capital letter</li>
          </ul>
        </div>

        <span className="">
          <div className="input-text-14-bold mt-4">Confirm New Password</div>
          <TextInput
            placeholder="Reenter your new password"
            value={confirmInputValue}
            onChange={handleConfirmInputChange}
            containerStyles="px-3 py-3 flex flex-1"
            secure={isConfirmPassword}
            rightIcon={
              <img
                alt=""
                className="Img-24 absolute right-6"
                src={!isConfirmPassword ? require("../../../assets/eye_open.png") : require("../../../assets/eye_close.png")}
                onClick={() => {
                  setIsConfirmPassword(!isConfirmPassword);
                }}
              />
            }
            textInputStyles={{ width: '85%' }}
          />
        </span>
      </div>
      <PrimaryButton
        onClick={onContinue}
        text="SAVE"
        containerStyles="py-2 mx-5"
        textStyle="inter-16-bold"
      />
    </div>
  );
};

export default EditPassword;
