import UserCardHeader from "./UserCardHeader";

const UserFeedCard = ({
  imageUrl = null,
  header = "",
  subHeader = "",
  type = "text",
  data = null,
}: {
  imageUrl?: string | null;
  header?: string;
  subHeader?: string;
  type: any;
  data?: any;
}) => {
  return (
    <div className="flex flex-1 flex-col px-3 pb-3 pt-5">
      <UserCardHeader data={data} />
      {type === "image" ? (
        <div className="flex flex-1">
          <img
            alt=""
            src={data?.url || "https://picsum.photos/200/300"}
            className="user-cardimage flex flex-1"
          />
        </div>
      ) : (
        <div className="inter-regular-24">{data?.answer}</div>
      )}
    </div>
  );
};

export default UserFeedCard;
