import { useEffect, useState } from "react";
import TextInput from "../../../components/TextInput";
import SecondaryButton from "../../../components/SecondaryButton";
import PrimaryButton from "../../../components/PrimaryButton";
import { useNavigate } from "react-router-dom";
import Dropdown from "../../../components/DropDown";
import { generateDateArray } from "../../../utils/utils";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import moment from "moment";
import ApiService from "../../../api/ApiServices";
import toast from "react-hot-toast";
import { setUserData } from "../../../redux/rootSlice";

const EditBirthday = () => {
  const navigate = useNavigate();
  const months = [...generateDateArray(12)];
  const currentYear = new Date().getFullYear();
  const [yearsArray, setYearsArray] = useState<{ value: string; label: string; }[]>([]);
  const userData = useAppSelector<any>(state => state.root.userData);
  const birthDay = userData?.dob;
  const [year, selectedYear] = useState();
  const [month, selectedMonth] = useState();
  const dispatch = useAppDispatch();
  const handleSelect = (option: any) => {
    selectedMonth(option.value)
  };
  const handleYearSelect = (option: any) => {
    selectedYear(option.value)
  };

  const onContinue = async () => {
    try {
      const response = await ApiService.updateUserDetails({
        dob:`${month}/${year}`,
      });
      if (response?.status) {
        toast.success(response?.message)
        dispatch(setUserData(response.data.user.dob));
      }
    } catch (error) {
      console.error("Error while updating date of birth:", error);
    }
  }


  useEffect(() => {
    // Function to generate years array
    const generateYearsArray = () => {
      const array = [];
      for (let year = 1990; year <= currentYear; year++) {
        array.push({ value: year.toString(), label: year.toString() });
      }
      return array;
    };

    // Set the years array state
    setYearsArray(generateYearsArray());
  }, [currentYear]);
  return (
    <div className="flex flex-1 flex-col relative pb-20 mx-1 pt-3 mb-5">
      <div className="flex flex-row mx-3 items-center">
        <div>
          <img
            onClick={() => {
              navigate(-1);
            }}
            alt=""
            className="home-frag-pic-backbtn mr-2"
            src={require("../../../assets/arrow-left.png")}
          />
        </div>
        <div className="btn-onb2-text flex flex-1 items-center justify-center">
          Edit Birthday
        </div>
      </div>
      <div className="flex flex-col flex-1 mx-3 mt-5">
        <div className="flex  flex-row">
          <Dropdown
            placeholder="Month"
            options={months}
            defaultValue={moment(birthDay).format('MM')}
            onSelect={handleSelect}
          />
          <span className="px-2" />
          <Dropdown
            placeholder="Year"
            options={yearsArray}
            defaultValue={moment(birthDay).format('YYYY')}
            onSelect={handleYearSelect}
          />
        </div>
      </div>
      <PrimaryButton
        onClick={onContinue}
        text="SAVE"
        containerStyles="py-2 mx-5"
        textStyle="inter-16-bold"
      />
    </div>
  );
};

export default EditBirthday;
