import { useNavigate } from "react-router";

const Chat = () => {
  const navigate = useNavigate();
  const renderTopGradient = () => (
    <svg
      // width="375"
      // height="136"
      viewBox="0 0 375 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: "absolute",
        top: 80,
        zIndex: -10,
      }}
    >
      <path
        d="M-38 0C133.539 24.1817 229.734 37.9713 396 0V93C218.223 39.9165 123.228 39.5123 -38 93V0Z"
        fill="url(#paint0_linear_1420_5392)"
        fill-opacity="0.8"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1420_5392"
          x1="-7.47784"
          y1="46.3524"
          x2="357.534"
          y2="46.3524"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.119792" stop-color="#FB6ADD" />
          <stop offset="0.260417" stop-color="#FF9D6C" />
          <stop offset="0.416667" stop-color="#FFDC64" />
          <stop offset="0.578125" stop-color="#42C17B" />
          <stop offset="0.75" stop-color="#3AC0E1" />
          <stop offset="0.963542" stop-color="#7595F5" />
        </linearGradient>
      </defs>
    </svg>
  );
  const renderProfile = () => {
    return (
      <div
        className="flex flex-1 items-center mx-3 left-0 right-0 flex-row absolute"
        style={{ top: 70 }}
      >
        <img
          src={require("../../../assets/phone.png")}
          alt=""
          className="phone"
        />
        <div className="flex flex-1 items-center justify-center ">
          <div className="chat-icon-cont px-7 py-5 relative">
            <img
              src="https://picsum.photos/200/300"
              alt=""
              className=" absolute chat-profile-item"
            />
          </div>
        </div>
        <img
          src={require("../../../assets/img_envelope.png")}
          alt=""
          className="envelope"
        />
      </div>
    );
  };

  // const renderTextInput = () => {
  //   return (
  //     <div className="">
  //       <img
  //         alt=""
  //         className="chat-input-icon"
  //         src={require("../../../assets/camera.png")}
  //       />
  //     </div>
  //   );
  // };
  return (
    <div className="flex flex-1 flex-col relative pb-20 pt-3 mb-5">
      <div className="flex flex-row mx-3 items-center">
        <div>
          <img
            onClick={() => {
              navigate(-1);
            }}
            alt=""
            className="home-frag-pic-backbtn mr-2"
            src={require("../../../assets/arrow-left.png")}
          />
        </div>
        <div className="btn-onb2-text flex flex-1 items-center justify-center">
          Jessica Snyder
        </div>
      </div>
      {renderTopGradient()}
      {renderProfile()}
      <div className="mt-40 flex items-center justify-center flex-col">
        <div className="inter-semibold-20">Jessica Snyder (she/her), 16</div>
        <div className="sub-title-text-16">
          Your Big Sister since August 2023
        </div>
      </div>
      <div className="flex flex-1"></div>
      {/* {renderTextInput()} */}
    </div>
  );
};
export default Chat;
