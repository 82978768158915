import { useEffect, useState } from "react";
import "./styles.css";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { useSpring, animated } from "@react-spring/web";
import { useNavigate } from "react-router-dom";
import { setAnimation } from "../redux/rootSlice";
import { AppPaths } from "../utils/AppPaths";
import { ReactComponent as StepThreeMessage } from "../assets/message_svh_three.svg";
import TypeWriter from "../components/TypeWriter";
import StepThree from "../components/StepThree";

const OnBoardingThree = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { step, isPopped, isModalAgeOpen } = useAppSelector(
    (state) => state.root
  );
  const { user } = useAppSelector((state) => state.onBoarding);
  const [shouldRender, setShouldRender] = useState(false);


  useEffect(() => {
    const timeout = setTimeout(() => {
      setShouldRender(true);
    }, 500);

    return () => clearTimeout(timeout);
  }, []);

  // const [isPopped, setIsPopped] = useState(false);
  // const [shouldRender, setShouldRender] = useState(false);

  const slidein = useSpring({
    from: { y: 500 },
    to: { y: 0 },
  });
  const springProps1 = useSpring({
    from: { marginRight: "20%" },
    to: { marginRight: "0%" },
    // loop: { reverse: true },
    config: { duration: 200 },
  });

  const popAnimation = useSpring({
    transform: isPopped ? "scale(1)" : "scale(0.9)",
    config: {
      duration: 100,
    },
  });

  const slideInAnimation = useSpring({
    opacity: shouldRender ? 1 : 0,
    transform: shouldRender ? "translateY(0%)" : "translateY(30%)",
    // config: {
    //   duration: 0,
    // },
  });

  useEffect(() => {
    if (step > 1) {
      springProps1.marginRight.start({
        from: { marginRight: "20%" },
        to: { marginRight: "0%" },
        // loop: { reverse: true },
        config: { duration: 200 },
      });
      setTimeout(() => {
        dispatch(setAnimation(true));
        slidein.y.start({
          from: { y: 500 },
          to: { y: 0 },
        });
      }, 200);
    } else {
      dispatch(setAnimation(true));
    }
  }, [dispatch, step]);

  const renderTopSvg = () => {
    return (
      <svg
        // width="100%"
        // height="210"
        viewBox="0 0 395 454"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "absolute",
          top: -40,
          zIndex: -1,
          // left: -10,
          // right: -10,
          // maxHeight: 320,
          // left: 0,
          // right: 0,
        }}
      >
        <path
          d="M-64 125L27.3314 101.816C137.894 73.7501 253.837 74.7737 363.886 104.787L438 125"
          stroke="#FFD3F6"
          stroke-width="210"
        />
      </svg>
    );
  };

  const renderHeader = () => {
    return (
      <div className="mt-5 mx-5 flex flex-row items-center ">
        <img
          onClick={() => {
            navigate(AppPaths.ONBOARDING_TWO);
          }}
          src={require("../assets/arrow-left.png")}
          className="arrow-left mr-2"
          alt=""
        />
        <div className="CountContainer rounded-2xl grid grid-row-1 gap-1 grid-flow-col relative ">
          <div
            // style={springProps2}
            className={`box box-1 flex flex-1  ${
              false && "bg-white"
            } rounded-l-2xl`}
          />
          <div
            // style={springProps2}
            className={`box box-2 flex flex-1  ${false && "bg-white"}`}
          />
          <div
            // style={springProps2}
            className={`box box-3 flex flex-1  ${false && "bg-white"}`}
          />
          <div
            // style={springProps2}
            className={`box box-4 flex flex-1  ${true && "bg-white"}`}
          />
          <div
            // style={springProps2}
            className={`box box-5 flex flex-1  ${true && "bg-white"}`}
          />
          <div
            // style={springProps2}
            className={`box box-6 flex flex-1  ${true && "bg-white"}`}
          />
          <div
            // style={springProps2}
            className={`box box-7 flex flex-1  ${true && "bg-white"}`}
          />
          <div
            // style={springProps2}
            className={`box box-8 flex flex-1 ${
              true && "bg-white"
            }  rounded-r-2xl`}
          />
          {/* 
          <animated.div
            // style={springProps2}
            style={{ left: `${leftValue}vh` }}
            className="box absolute right-0 rounded-r-2xl bg-black"
          ></animated.div> */}
        </div>
      </div>
    );
  };

  const renderHeaderImage = () => {
    return (
      <span className="flex flex-1 justify-center relative">
        <img
          alt=""
          src={require("../assets/Item3.png")}
          className="onb1-3 inline-block mr-[-10px] mt-3"
        />
        <span className=" flex justify-center relative">
          <animated.div
            className="relative"
            style={{ ...springProps1, ...popAnimation }}
          >
            <div className="stepOnetext">
              <TypeWriter
                key={step}
                text="With us, you journey at your own pace with activities tailored to U."
                text2="What shoes do you journey through life in?"
                delay={40}
              />
            </div>
            <StepThreeMessage />
          </animated.div>
        </span>
      </span>
    );
  };

  return (
    <div className="flex flex-col h-svh relative bg-white z-10">
      {renderTopSvg()}
      <div className="z-11">
        {renderHeader()}
        <div className="mt-3 mx-3">{renderHeaderImage()}</div>
      </div>
      <animated.div
        className="flex flex-col justify-center flex-1 py-4 overflow-y-auto "
        // style={{ ...slideInAnimation, ...slidein }}
      >
        {shouldRender && <StepThree />}
      </animated.div>
    </div>
  );
};

export default OnBoardingThree;
