export const AppPaths = {
  // Unprotected paths
  SIGNUP: "/sign-up",
  ONBOARDING: "/onboarding",
  ONBOARDING_ONE: "/onboarding-one",
  ONBOARDING_TWO: "/onboarding-two",
  ONBOARDING_THREE: "/onboarding-three",
  ONBOARDING_FOUR: "/onboarding-four",
  ONBOARDING_FIVE: "/onboarding-five",
  ONBOARDING_SIX: "/onboarding-six",
  ONBOARDING_SEVEN: "/onboarding-seven",
  ONBOARDING_EIGHT: "/onboarding-eight",
  EMAILVERIFICARION: "/email-verification",
  AUTH_LOADING: "/auth-loading",
  LOGIN: "/login",
  VERIFY_ACCOUNT: "/verify-account",
  DASHBOARD: "/dashboard",
  HOME: "/dashboard/home",
  HALLOWEEN_CHALLENGE: "/dashboard/halloween-challenge",
  FALLFUN_QUIZ_CHALLENGE: "/dashboard/fallfun-quiz-challenge",
  DANCE_CHALLENGE: "/dashboard/dance-challenge",
  EMOJI_MODAL: "/emoji-modal",
  TIME_QUIZ: "/time-quiz",
  PROFILE: "/dashboard/profile",
  MESSAGES: "/dashboard/messages",
  CHAT: "/dashboard/chat",
  MOODRING: "/dashboard/moodring",
  MYJOURNEY: "/dashboard/myjourney",
  EDIT_USERNAME: "/dashboard/edit-username",
  EDIT_PASSWORD: "/dashboard/edit-password",
  EDIT_LOCATION: "/dashboard/edit-location",
  EDIT_BIRTHDAY: "/dashboard/edit-birthday",
  EDIT_GRADE: "/dashboard/edit-grade",
  TECHNICAL_SUPPORT: "/dashboard/technical-support",
};
