import Api from "./Api";

export interface Login {
  email: string;
  password: string;
}

export interface ApiResponse {
  status: boolean;
  data: string;
  message: string;
}

const ApiService = {
  async login(data: Login): Promise<ApiResponse> {
    return Api.post<ApiResponse>("/users/login", data);
  },
  async createUser(data: any): Promise<any> {
    return Api.post<ApiResponse>("/users/create", data);
  },
  async getProfile(): Promise<any> {
    return Api.get<ApiResponse>("/users");
  },
  async dailyCheckIn(data: any): Promise<any> {
    return Api.post<ApiResponse>("users/daily_checkin", data);
  },
  async getMessages(): Promise<any> {
    return Api.get<ApiResponse>("users/contacts");
  },
  async getMyJourney(): Promise<any> {
    return Api.get<ApiResponse>("users/myjourney");
  },
  async updateUserDetails(data: any): Promise<any> {
    return Api.put<ApiResponse>("users/update", data);
  },
  async updateUserPassword(data: any): Promise<any> {
    return Api.post<ApiResponse>("users/forgotPassword", data);
  },
  async getMoodRingData(month: any): Promise<any> {
    return Api.get<ApiResponse>(`users/moodRings/${month}`);
  },
  async answerText(data: any): Promise<any> {
    return Api.post<ApiResponse>("users/challenges/attempt", data);
  },
};

export default ApiService;
