import React, { useEffect, useState } from "react";
import "./App.css";
import AuthRoutes from "./navigation/AuthRoutes";
import { useAppSelector } from "./redux/store";
import RootRoutes from "./navigation/RootRoutes";
import secureLocalStorage from "react-secure-storage";
import { Toaster } from 'react-hot-toast'
function App() {
  const { login } = useAppSelector((state) => state.root);
  const [accessToken, setAccessToken] = useState<any>(null);

  useEffect(() => {
    const storage = secureLocalStorage.getItem("user");
    console.log("storage", storage);

    setAccessToken(storage);
  }, [login]);

  return (
    <div className="App h-screen w-screen relative ">
      <Toaster />
      {!accessToken ? (
        <AuthRoutes />
      ) : (
        <>
          <RootRoutes />
        </>
      )}
    </div>
  );
}

export default App;
