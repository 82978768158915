import "../../container/styles.css";

const HomeDancePicFragment = ({
  onClick,
  hideOptions = false,
  imgStyle = null,
  contStyle = null,
}: {
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  hideOptions?: boolean;
  imgStyle?: any;
  contStyle?: any;
}) => {
  return (
    <div
      onClick={onClick}
      className={`flex flex-col flex-1 home-frag-cont relative ${
        !hideOptions && `mr-3`
      }
      ${contStyle && contStyle}
      `}
    >
      <img
        alt=""
        className={`home-frag-img ${imgStyle}`}
        src={require("../../assets/danceFrag.png")}
      />
    </div>
  );
};

export default HomeDancePicFragment;
