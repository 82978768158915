import HomeScrollPicFragment from "../../components/dashboard/HomeScrollPicFragment";
import HomeScrollQuizFragment from "../../components/dashboard/HomeScrollQuizFragment";
import HomeTextFragment from "../../components/dashboard/HomeTextFragment";
import HomeScrollVidFragment from "../../components/dashboard/HomeScrollVidFragment";
import PrimaryButton from "../../components/PrimaryButton";
import TextInput from "../../components/TextInput";
import { useEffect, useState } from "react";
import UserFeedard from "../../components/dashboard/UserFeedCard";
import { useNavigate } from "react-router-dom";
import { AppPaths } from "../../utils/AppPaths";
import EmojiModal from "../../components/dashboard/EmojiModal";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  setActiveTab,
  setEmojiModal,
  setPointsTracker,
  setUserData,
} from "../../redux/rootSlice";
import PointsTrackerModal from "../../components/PointsTrackerModal";
import { setSelctedImoji } from "../../redux/homeScreenSlice";
import LoadImage from '../../assets/loader.gif';
import {
  AnxiousImages,
  InBetweenImage,
  difficultImage,
  getColor,
  getImageUrl,
  happyImages,
} from "../../constants/reactionImages";
import ApiService from "../../api/ApiServices";

const HomeScreen = () => {
  const [bedTime, setBedTime] = useState("");
  const [wokeUpTime, setWokeUpTime] = useState("");
  const [submit, setSubmit] = useState(false);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [responseData, setResponseData] = useState<any>(null);
  const [load, setLoad] = useState<boolean>(false);
  const { emojiModal } = useAppSelector((state) => state.root);
  const { selectedImoji, recentUsedImoji } = useAppSelector(
    (state) => state.home
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setActiveTab(2));
  }, [dispatch]);
  useEffect(() => {
    dispatch(setSelctedImoji(Object.entries(happyImages)[0]));
    dispatch(setSelctedImoji(Object.entries(InBetweenImage)[1]));
    dispatch(setSelctedImoji(Object.entries(happyImages)[1]));
    dispatch(setSelctedImoji(Object.entries(AnxiousImages)[0]));
  }, []);

  const navigate = useNavigate();

  const handleBedTimeChange = (e: any) => {
    setBedTime(e.target.value);
  };
  const handleWokeUpChange = (e: any) => {
    setWokeUpTime(e.target.value);
  };

  const handleSubmit = async () => {
    const response = await ApiService.dailyCheckIn({
      bedTime: bedTime,
      wakeupTime: wokeUpTime,
      todayFeeling: selectedImoji?.[0],
    }).then((data) => {
      if (data?.status) {
        setResponseData(data?.data);
      }
    });
  }

  useEffect(() => {
    if (responseData) {
      setSubmit(true);
    }
  }, [responseData])

  useEffect(() => {
    if (userDetails) {
      if (userDetails?.user?.userCheckin) {
        // setSubmit(true);
      }
    }
  }, [userDetails])

  const getFeedData = async () => {
    setLoad(true);
    const response = await ApiService.getProfile();
    setUserDetails(response?.data);
    dispatch(setUserData(response?.data?.user))
    setLoad(false)
  }

  useEffect(() => {
    getFeedData()
  }, [])

  const renderTopGradient = () => (
    <svg
      // width="375"
      // height="123"
      viewBox="0 0 365 143"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: "absolute",
        top: -32,
        zIndex: -10,
      }}
    >
      <path
        d="M-66 43L44.741 18.2962C142.95 -3.6119 244.864 -2.80936 342.716 20.6427L436 43"
        stroke="url(#paint0_linear_1102_5554)"
        stroke-width="133"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1102_5554"
          x1="16"
          y1="43"
          x2="363"
          y2="43"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF9EEB" />
          <stop offset="0.145833" stop-color="#FF9728" stop-opacity="0.74" />
          <stop offset="0.302083" stop-color="#FFD203" stop-opacity="0.61" />
          <stop offset="0.510417" stop-color="#F1F232" stop-opacity="0.48" />
          <stop offset="0.65625" stop-color="#06AE56" stop-opacity="0.67" />
          <stop offset="0.859375" stop-color="#22A094" stop-opacity="0.77" />
          <stop offset="0.994236" stop-color="#91A8EC" />
        </linearGradient>
      </defs>
    </svg>
  );
  const renderHeaderImages = (data: any) => {
    return (
      <div className="flex z-10 mb-4 pt-1">
        <div
          onClick={() => {
            dispatch(setPointsTracker(true));
          }}
          className="flex flex-row flex-1 pl-5 items-center"
        >
          <img
            src={require("../../assets/header_jem.png")}
            alt=""
            className="header-jem"
          />
          <div className="inter-text-16-extrabold px-3">{data?.challengePoints}&nbsp;pts</div>
        </div>
        <img
          src={require("../../assets/u4uOnb.png")}
          alt=""
          className="header-u4u"
        />
        <div className="flex flex-row flex-1 justify-end pr-5 items-center">
          <img
            src={require("../../assets/header_star.png")}
            alt=""
            className="header-star mr-3 "
          />
          <div className="inter-text-16-extrabold">{data?.checkInPoints}&nbsp;pts</div>
        </div>
      </div>
    );
  };

  const renderCheckInSaved = () => {
    return (
      <>
        {Object.keys(responseData).length === 0 ? <div className="home-frag-checkin mx-3 flex flex-row flex-1 py-4 justify-center">
          <p>Already submit daily check in!</p>
        </div> : <div className="home-frag-checkin mx-3 flex flex-row flex-1">
          <div className="dailt-check-box-filled flex flex-1 flex-col items-center py-4 ">
            <div className="sub-title-text-16">I’m feeling...</div>
            <img
              alt=""
              className="emoji-l"
              src={getImageUrl(responseData?.todayFeeling)}
            />
            <div className="onb1-text">{responseData?.todayFeeling}</div>
          </div>
          <div className="flex flex-1 flex-col items-center py-4">
            <div className="sub-title-text-16">I slept...</div>
            <div className="flex flex-row items-baseline">
              <div className="input-text-44-bold">{responseData?.timeDiff?.hours}</div>
              <div className="input-text-12-bold inline-block">HRS</div>
            </div>
            <div className="inter-regular-12">{responseData?.bedTime} - {responseData?.wakeupTime}</div>
          </div>
        </div>}
      </>
    );
  };

  const renderCheckInCard = () => {
    return (
      <div className="home-frag-checkin mx-3 py-3">
        <div className="inter-18-bold mx-3">I’m feeling...</div>

        <div className="flex flex-row overflow-x-auto ml-3 py-2">
          <div className="grid grid-flow-col grid-rows-1 gap-1">
            {recentUsedImoji?.slice().reverse()?.map((img: any) => (
              <div
                onClick={() => {
                  dispatch(setSelctedImoji(img));
                }}
                className={`emoji-cont emoji-cont-width flex flex-col items-center ${selectedImoji?.[0] === img?.[0] && "emoji-selected"
                  } `}
                style={{
                  backgroundColor: selectedImoji
                    ? getColor(img?.[0])
                    : undefined,
                }}
              >
                <img src={img?.[1]} alt="" className="emoji" />
                <div
                  className="inter-text-9-semibold text-center flex flex-row items-center  flex-wrap   pt-2"
                // style={{ whiteSpace: "pre-wrap", width: 50 }}
                >
                  {img?.[0]}
                </div>
              </div>
            ))}
            <div
              onClick={() => {
                dispatch(setEmojiModal(true));
                // navigate(AppPaths.EMOJI_MODAL);
              }}
              className="emoji-cont emoji-cont-width flex flex-col items-center"
            >
              <div className="flex flex-1 items-center ">
                <img
                  src={require("../../assets/plus.png")}
                  alt=""
                  className="add my-2"
                />
              </div>
              <div className="inter-text-9-semibold text-center flex flex-row items-center  flex-wrap   ">
                See More
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-1 flex-row mx-3 my-3 ">
          <div className="flex flex-1 flex-row items-center mr-4 justify-between ">
            <div className="inter-regular-14 text-center mr-2 flex flex-1">
              I went to bed at...
            </div>
            {false ? (
              <img
                alt=""
                src={require("../../assets/moon_empty.png")}
                className="daily-check-time-icon-2"
              />
            ) : (
              <img
                alt=""
                src={require("../../assets/sleep_filled.png")}
                className="daily-check-time-icon"
              />
            )}
          </div>
          <div className="flex flex-1 flex-row items-center justify-between">
            <div className="inter-regular-14 text-center mr-2 ">
              I woke up at...
            </div>
            {false ? (
              <img
                alt=""
                src={require("../../assets/sun_empty.png")}
                className="daily-check-time-icon"
              />
            ) : (
              <img
                alt=""
                src={require("../../assets/sun-filles.png")}
                className="daily-check-time-icon"
              />
            )}
          </div>
        </div>
        <div className="flex flex-1 flex-row mx-3 mb-3">
          {/* <TextInput
            placeholder="--:--"
            value={bedTime}
            textStyle={"text-center"}
            onChange={handleBedTimeChange}
            containerStyles="px-3 py-2 mr-3"
            textInputStyles={{ maxWidth: "100%" }}
          /> */}
          <div className="TextInput flex flex-1 flex-row  px-3 py-2 ">
            <input type="time" className="font-black inter-regular-16 text-center outline-none w-full" value={bedTime} onChange={handleBedTimeChange} min="09:00" max="12:00" />
          </div>

          <div className="TextInput flex flex-1 flex-row  px-3 py-2 ">
            <input type="time" className="font-black inter-regular-16 text-center outline-none w-full" value={wokeUpTime} onChange={handleWokeUpChange} min="09:00" max="12:00" />
          </div>


          {/* <TextInput
            placeholder="--:--"
            value={wokeUpTime}
            textStyle={"text-center"}
            onChange={handleWokeUpChange}
            containerStyles="px-3 py-2"
            textInputStyles={{ maxWidth: "100%" }}
          /> */}
        </div>
        <PrimaryButton
          text="SUBMIT"
          onClick={() => {
            // setSubmit(true);
            handleSubmit();
          }}
          containerStyles="px-1 py-1 mx-3 "
          textStyle="inter-12-bold"
        />
      </div>
    );
  };

  const renderChallenges = (challenge: any) => {
    if (challenge?.type === "text") {
      return <HomeTextFragment
        id={challenge?._id}
      />
    } else if (challenge?.type === "video") {
      return <HomeScrollVidFragment id={challenge?._id} />
    } else if (challenge?.type === "image") {
      return <HomeScrollPicFragment id={challenge?._id} />
    }
    return <HomeScrollQuizFragment/>
  }

  return (
    <>
    {load ? <div>
      <img height="200" width="auto" src={LoadImage} alt="loader" />
    </div> : <div className="flex flex-col relative pb-20">
      {/* <div className="gradient-container absolute top-0 left-0 right-0 "></div> */}
      <div className="absolute">
        <EmojiModal
          setVisible={(val) => {
            dispatch(setEmojiModal(val));
          }}
          visible={emojiModal}
        />
        {userDetails && <PointsTrackerModal data={userDetails?.houseMatches} />}
      </div>
      {renderTopGradient()}
      {renderHeaderImages(userDetails)}
      <div className="inter-18-bold text-center">Welcome home, {userDetails?.user?.name}!</div>
      {console.log('user', userDetails)}
      <div className="flex pl-3 py-2 overflow-x-auto mt-1 ">
        {userDetails?.challenges?.map((item: any) => {
          return renderChallenges(item)
        })
        }
      </div>
      <div className="flex flex-row pt-4 px-3 mb-2 items-center">
        <div className="inter-18-bold text-center mr-2">Daily Check-in</div>
        <div className="inter-text-14-semibold text-center flex flex-row items-center rating">
          <img
            src={require("../../assets/header_star.png")}
            alt=""
            className="h-5 w-5 mr-1"
          />
          {userDetails?.checkInPoints} pts
        </div>
        {submit && (
          <div
            className="flex flex-1 justify-end"
            onClick={() => {
              setSubmit(false);
            }}
          >
            <img
              src={require("../../assets/edit.png")}
              alt=""
              className="h-5 w-5 mr-1"
            />
          </div>
        )}
      </div>
      {!submit ? renderCheckInCard() : renderCheckInSaved()}
      {userDetails?.feeds?.map((item: { challengeId: { type: any; data: any; }; }) => {
        return <UserFeedard data={item} type={item?.challengeId?.type} />
      })}
      {/* <UserFeedard type="img" /> */}
      {/* <UserFeedard type="text" /> */}
    </div>}
    </>
  );
};

export default HomeScreen;
