export const generateDateArray = (num = 10) => {
  const months = [];
  for (let i = 1; i <= num; i++) {
    const month = {
      value: i.toString(),
      label: i.toString(),
    };
    months.push(month);
  }
  return months;
};
export const generateMonthArray = (num: Array<string> = []) => {
  const months = [];
  for (let i = 0; i < num.length; i++) {
    const month = {
      value: num[i],
      label: num[i],
    };
    months.push(month);
  }
  return months;
};

export const generateAgeArray = (num = 18) => {
  const currentYear = new Date().getFullYear();
  const ages = [];

  for (let i = 1; i <= num; i++) {
    const age = {
      value: (currentYear - i).toString(),
      label: (currentYear - i).toString(),
    };
    ages.push(age);
  }

  return ages;
};

