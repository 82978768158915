import { useEffect, useState } from "react";
import TextInput from "../../../components/TextInput";
import SecondaryButton from "../../../components/SecondaryButton";
import PrimaryButton from "../../../components/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import ApiService from "../../../api/ApiServices";
import { setUserData } from "../../../redux/rootSlice";
import toast from 'react-hot-toast'

const EditUserName = () => {
  const [inputValue, setInputValue] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userData = useAppSelector<any>(state => state.root.userData);
  const defaultName = userData ? userData.name : "";

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedName = e.target.value;
    setInputValue(updatedName);
  };

  const onContinue = async () => {
    try {
      const response = await ApiService.updateUserDetails({
        name: inputValue,
      });
      if (response?.status) {
        toast.success(response?.message)
        dispatch(setUserData(response.data.user.name));
      }
    } catch (error) {
      console.error("Error while updating username:", error);
    }
  }

  useEffect(() => {
    setInputValue(defaultName);
  }, [defaultName]);
  return (
    <div className="flex flex-1 flex-col relative pb-20 mx-1 pt-3 mb-5">
      <div className="flex flex-row mx-3 items-center">
        <div>
          <img
            onClick={() => {
              navigate(-1);
            }}
            alt=""
            className="home-frag-pic-backbtn mr-2"
            src={require("../../../assets/arrow-left.png")}
          />
        </div>
        <div className="btn-onb2-text flex flex-1 items-center justify-center">
          Edit Username
        </div>
      </div>
      <div className="flex flex-col flex-1 mx-3">
        <span className="">
          <div className="input-text-14-bold mt-4">Username</div>
          <TextInput
            placeholder="Enter your username"
            value={inputValue}
            onChange={handleInputChange}
            containerStyles="px-3 py-3 flex flex-1"
          />
        </span>
      </div>
      <PrimaryButton
        onClick={onContinue}
        text="SAVE"
        containerStyles="py-2 mx-5"
        textStyle="inter-16-bold"
      />
    </div>
  );
};

export default EditUserName;
