import { useEffect, useState } from "react";
import TextInput from "../../../components/TextInput";
import PrimaryButton from "../../../components/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import toast from "react-hot-toast";
import { setUserData } from "../../../redux/rootSlice";
import ApiService from "../../../api/ApiServices";

const EditLocation = () => {
  const dispatch = useAppDispatch();
  const [cityValue, setCityValue] = useState("");
  const [stateValue, setStateValue] = useState();
  const navigate = useNavigate();
  const userData = useAppSelector<any>(state => state.root.userData);
  const city = userData?.questions['Where are you from?'].city
  const state = userData?.questions['Where are you from?'].state

  const options = [
    { label: "India", value: "india" },
    { label: "Usa", value: "Usa" },
    { label: "Canada", value: "Canada" },
    { label: "Africa", value: "Africa" },
    { label: "UK", value: "UK" },
  ];
  const handleCityInputChange = (e: any) => {
    setCityValue(e.target.value);
  };

  const handleStateInputChange = (value: any) => {
    setStateValue(value);
  };

  const onContinue = async () => {
    try {
      const response = await ApiService.updateUserDetails({
        city: cityValue,
        state: stateValue
      });
      if (response?.status) {
        toast.success(response?.message)
        console.log('res', response)
        // dispatch(setUserData(response.data.user?.questions['Where are you from?'].city));
        // dispatch(setUserData(response.data.user?.questions['Where are you from?'].state));
      }
    } catch (error) {
      console.error("Error while updating username:", error);
    }
  }

  useEffect(() => {
    if (city) {
      setCityValue(city)
    }
  }, [city])

  useEffect(() => {
    if (state) {
      setStateValue(state)
    }
  }, [state])
  return (
    <div className="flex flex-1 flex-col relative pb-20 mx-1 pt-3 mb-5">
      <div className="flex flex-row mx-3 items-center">
        <div>
          <img
            onClick={() => {
              navigate(-1);
            }}
            alt=""
            className="home-frag-pic-backbtn mr-2"
            src={require("../../../assets/arrow-left.png")}
          />
        </div>
        <div className="btn-onb2-text flex flex-1 items-center justify-center">
          Edit Location
        </div>
      </div>
      <div className="flex flex-col flex-1 mx-3">
        <span className="">
          <div className="input-text-14-bold mt-4">City</div>
          <TextInput
            placeholder=""
            value={cityValue}
            onChange={handleCityInputChange}
            containerStyles="px-3 py-3 flex flex-1"
          />
        </span>
        <span className="">
          <div className="input-text-14-bold mt-4">State</div>
          {/* <TextInput
            placeholder=""
            value={stateValue}
            onChange={handleStateInputChange}
            containerStyles="px-3 py-3 flex flex-1"
          /> */}
          <div className="TextInputDropDown   flex flex-1 flex-row items-cente px-3 py-3 w-full">
            <select
              value={stateValue || ""}
              className={`flex flex-1  decoration-none outline-none bg-white selectOptions ${stateValue && "font-black"
                }`}
              // onChange={(e) => {
              //   const item = options?.find(
              //     (option) => option.value === e.target.value
              //   );
              //   if (item) {
              //     handleStateInputChange(item.value);
              //   }
              // }}
              onChange={(e) => handleStateInputChange(e.target.value)}
            >
              <option value="" disabled hidden>
                Select your state
              </option>
              {options?.map((option) => (
                <option className="flex flex-1 outline-none">
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </span>
      </div>
      <PrimaryButton
        onClick={onContinue}
        text="SAVE"
        containerStyles="py-2 mx-5"
        textStyle="inter-16-bold"
      />
    </div>
  );
};

export default EditLocation;
