/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import "../styles.css"; // Import your styling file
import { useNavigate } from "react-router-dom";
import { setActiveTab } from "../../redux/rootSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";

const BottomTabs = ({
  tabs,
  // onTabChange,
}: {
  tabs: Array<{
    path: string;
    id: number;
    url: string;
  }>;
  // onTabChange: (e: number) => void;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedTab } = useAppSelector((state) => state.root);
  const [focusTab, setFocusTab] = useState(3);
  const handleTabClick = (tab: any) => {
    dispatch(setActiveTab(tab.id));
    setFocusTab(tab.id)
    navigate(tab.path);
  };

  useEffect(() => {
    const currentTab = tabs.find((tab) => tab.path === location.pathname);
    if (currentTab) {
      setFocusTab(currentTab.id);
    }
  }, [location.pathname, tabs]);
  
  return (
    <div className="BottomTabsContainer">
      <div className="BottomTabsContent">
        {tabs.map((tab: any, index) => (
          <div
            key={tab.id}
            className={`BottomTab ${focusTab === tab.id ? "active magnify" : ""
              }`}
            onClick={() => handleTabClick(tab)}
          >
            {index === 0 ? (
              <div className="mr-2">
                <img
                  alt=""
                  src="https://picsum.photos/200/300"
                  className={`user-cardheader h-8 w-8  ${focusTab === tab.id ? "h-11" : ""
                    } `}
                />
              </div>
            ) : (
              <img
                src={tab.url}
                alt=""
                className={`h-8 w-8  ${focusTab === tab.id ? "h-11" : ""}`}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BottomTabs;
