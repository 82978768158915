import moment from "moment";

const UserCardHeader = ({
  imageUrl = null,
  header = "",
  subHeader = "",
  data = null,
}: {
  imageUrl?: string | null;
  header?: string;
  subHeader?: string;
  data?: any;
}) => {
  return (
    <div className="flex flex-1 flex-row py-2">
      <div className="mr-2">
        <img
          alt=""
          src={"https://picsum.photos/200/300"}
          className="user-cardheader"
        />
      </div>
      <div>
        <div className="inter-12-bold">{data?.userId?.name}</div>
        <div className="inter-regular-12">{moment(data?.createdAt).format('MMM DD, YYYY')} - {data?.challengeId?.title}</div>
      </div>
    </div>
  );
};

export default UserCardHeader;
