import { useState } from "react";
import TextInput from "../TextInput";
import PrimaryButton from "../PrimaryButton";
import "../../container/styles.css";
import ApiService from "../../api/ApiServices";
import toast from "react-hot-toast";

const HomeScrollText = ({
  onClick,
  imgStyle = null,
  contStyle = null,
  id,
}: {
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  imgStyle?: any;
  contStyle?: any;
  id?: any;
}) => {
  const [inputValue, setInputValue] = useState("");
  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      const response = await ApiService.answerText({
        challengeId: id,
        answer: inputValue,
      });
      if (response?.status) {
        toast.success(response?.message)
        setInputValue('')
      }
    } catch (error) {
      console.error("Error while submitting answer:", error);
    }
  }
  return (
    <div
      onClick={onClick}
      className={`flex flex-col flex-1 home-frag-cont mr-3 ${
        contStyle && contStyle
      }`}
    >
      <img
        alt=""
        className={`home-frag-img ${imgStyle}`}
        src={require("../../assets/homeFragText.png")}
      />
      <div className="flex flex-row items-center">
        <TextInput
          placeholder="Enter your answer"
          value={inputValue}
          containerStyles="ml-3 pl-2 py-2 my-2 flex flex-1"
          onChange={handleInputChange}
          // textInputStyles={{ maxWidth: "50%" }}
        />
        <div className={`primary-btn flex px-1 py-1 mx-2`} onClick={handleSubmit}>
          <div className={`inter-12-bold text-white px-1`}>SUBMIT</div>
        </div>
      </div>
    </div>
  );
};

export default HomeScrollText;
