import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { setStep } from "../redux/rootSlice";
import { AppPaths } from "../utils/AppPaths";
import "./styles.css";
import PrimaryButton from "./PrimaryButton";
import Dropdown from "./DropDown";
import {
  generateAgeArray,
  generateDateArray,
  generateMonthArray,
} from "../utils/utils";
import TextInput from "./TextInput";
import { setUser } from "../redux/OnBoardingSlice";

const AgeModal = ({
  visible = false,
  setVisible,
}: {
  visible: boolean;
  setVisible: (e: boolean) => void;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { step } = useAppSelector((state) => state.root);
  const { user } = useAppSelector((state) => state.onBoarding);
  // const months = [...generateDateArray(12)];

  const monthsText = generateMonthArray([
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]);

  const dates = generateAgeArray(18);
  const [inputValue, setInputValue] = useState<Record<string, string>>({});
  const [selectedData, setSelectedData] = useState({});

  const handleInputChange = (e: string, type: string) => {
    setInputValue({
      ...inputValue,
      [type]: e,
    });
  };

  const [modalStep, setModalStep] = useState<number>(1);

  const handleBackdropClick = (e: any) => {
    if (e.target.classList.contains("ModalOverlay")) {
      setVisible(false);
      setModalStep(1);
    }
  };
  const handleSelect = (
    option: {
      value: string;
      label: string;
    },
    type: string
  ) => {
    console.log("Selected option:", option);

    setSelectedData({
      ...selectedData,
      [type]: option.value,
    });
  };
  const onContinue = () => {
    setVisible(false);
    // dispatch(setStep(step + 1));
    navigate(AppPaths.ONBOARDING_SEVEN);
  };

  const renderModalStep = () => {
    if (modalStep === 1) {
      return (
        <>
          <div className="flex flex-1 flex-row">
            <Dropdown
              placeholder="Month"
              options={monthsText}
              onSelect={(ele) => handleSelect(ele, "month")}
            />
            <span className="px-2" />
            <Dropdown
              placeholder="Year"
              options={dates}
              // onSelect={handleSelect}
              onSelect={(ele) => handleSelect(ele, "date")}
            />
          </div>
          <div className="pt-5 mb-5">
            <PrimaryButton
              text="CONTINUE"
              onClick={() => {
                if (modalStep === 1) {
                  dispatch(setUser({ ...user, dob: selectedData }));
                  setModalStep(2);
                } else {
                  onContinue();
                }
              }}
              textStyle="inter-16-bold "
              containerStyles="py-3 px-8 mx-5 mt-2"
            />
          </div>
        </>
      );
    }

    if (modalStep === 2) {
      return (
        <>
          <div className="TextInputContainer mt-1">
            <span className="">
              <span className="flex flex-row items-center">
                <div className="input-text-14-bold">
                  Parent/Guardian Email Address
                </div>
              </span>
              <TextInput
                placeholder="Enter email"
                value={inputValue?.email}
                onChange={(val) => handleInputChange(val.target.value, "email")}
                containerStyles="px-3 py-3"
              />
            </span>
            <span className="">
              <span className="flex flex-row items-center mt-6">
                <div className="input-text-14-bold">
                  Parent/Guardian Phone Number
                </div>
              </span>
              <TextInput
                placeholder="(---) --- ---"
                value={inputValue?.ph}
                onChange={(val) => handleInputChange(val.target.value, "ph")}
                containerStyles="px-3 py-3"
              />
            </span>
            <div className="pt-5 mb-5">
              <PrimaryButton
                text="CONTINUE"
                onClick={() => {
                  onContinue();
                }}
                textStyle="inter-16-bold"
                containerStyles="py-3 px-8 mt-3"
              />
            </div>
          </div>
        </>
      );
    }
  };
  return (
    <div className="ModalContainer z-10  absolute">
      {/* <button className="Button pinkBtn" onClick={handleModalToggle}>
        <div className="ButtonText pinkBtnText">Open modal</div>
      </button> */}
      {visible && (
        <div className="ModalOverlay" onClick={handleBackdropClick}>
          <div className="ModalContent">
            <div className="ModalHeader pt-3">
              {/* <span className="CloseModalButton" onClick={handleModalToggle}>
                &times;
              </span> */}
              <span className="inter-18-bold text- flex flex-row items-center flex-1 justify-center">
                {modalStep === 1
                  ? "Tell us your birth month & year:"
                  : "We luv new members under 13 yrs!"}
              </span>
              {modalStep === 2 && (
                <div style={{ textAlign: 'center' }}>
                  <span className="sub-title-text-16 flex flex-row items-center flex-1 justify-center mt-2">
                    Please enter a parent/guardian’s email address OR phone number below:
                  </span>
                </div>
              )}
            </div>
            <div className="ModalBody mb-2 mx-4">{renderModalStep()}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AgeModal;
