import { PieChart } from "react-minimal-pie-chart";
import "../styles.css";
import { useEffect, useState } from "react";
const MoodRingModal = ({
  visible = false,
  setVisible,
  data
}: {
  visible: boolean;
  setVisible: (e: boolean) => void;
  data: any;
}) => {
  // const navigate = useNavigate();
  const [formattedData, setFormattedData] = useState<any>(null);
  const handleBackdropClick = (e: any) => {
    if (e.target.classList.contains("ModalOverlay")) {
      setVisible(false);
    }
  };

  const getColor = (index: number) => {
    const colors = ["#E8A4E0", "#ECC542", "#69E2E3", "#F1F232"];
    return colors[index % colors.length];
  };

  useEffect(() => {
    const newData = data?.moodTracker.map((item: { mood: any; value: any; }, index: number) => ({
      title: item.mood,
      value: item.value,
      color: getColor(index)
    }));
    setFormattedData(newData)
  }, [data])

  const renderSVG = () => {
    return (
      <svg
        // width="318"
        // height="93"
        className="absolute top-10 left-0 right-0"
        viewBox="0 0 318 93"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          zIndex: -1,
        }}
      >
        <path
          d="M0 0C125.69 24.1817 196.174 37.9713 318 0V93C187.74 39.9165 118.135 39.5123 0 93V0Z"
          fill="url(#paint0_linear_1153_2321)"
          fill-opacity="0.8"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1153_2321"
            x1="22.3642"
            y1="46.3524"
            x2="289.815"
            y2="46.3524"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E8A4E0" />
            <stop offset="0.197917" stop-color="#ECC542" />
            <stop offset="0.416667" stop-color="#F5F552" />
            <stop offset="0.578125" stop-color="#B6EC80" />
            <stop offset="0.75" stop-color="#69E2E3" />
            <stop offset="0.979167" stop-color="#C0A4EE" />
          </linearGradient>
        </defs>
      </svg>
    );
  };

  const renderCardImage = () => {
    return (
      <div className="z-10 flex justify-center">
        <img
          alt=""
          className="moodring-mood-image mt-2"
          src={require("../../assets/moodring.png")}
        />
      </div>
    );
  };

  const renderMoodRingCard = () => {
    return (
      <div className="moodring-card-mood px-0 py-2 relative z-10 mt-2 m-3">
        {renderSVG()}
        {renderCardImage()}
        <div className="flex items-center flex-col z-30">
          <div className="sub-title-text-16 text-center">
            Your positive outlook will take you
            <br /> anywhere you want to go.
          </div>
        </div>
      </div>
    );
  };

  const renderColumTwo = () => {
    return (
      <div className="flex flex-1 flex-col">
        <div className="flex flex-1 flex-col items-center justify-center card1-bg border-rad-card1">
          <img
            alt=""
            className="mood-ring-zzz"
            src={require("../../assets/moodringZZZ.png")}
          />
          <div className="inter-24-bold">{data?.averageSleepDurationHours} hours</div>
          <div className="sub-title-text-16">
            average of your
            <br /> sleep per night{" "}
          </div>
          <div className="inter-regular-10 italic">
            30 more minutes than last period
          </div>
        </div>
        <div className="py-1" />
        <div className="flex flex-1 flex-col items-center justify-center card2-bg border-rad-card1">
          <img
            alt=""
            className="mood-ring-zzz"
            src={require("../../assets/moodring_check.png")}
          />
          <div className="inter-24-bold">{data?.totalDaysCheckedIn}/14</div>
          <div className="sub-title-text-16">
            days you
            <br /> checked in
          </div>
          <div className="inter-regular-10 italic">3 less than last period</div>
        </div>
      </div>
    );
  };

  const renderColumOne = () => {
    return (
      <div className="flex flex-1 flex-col light-pink-bg border-rad-card1 pt-5">
        <div className="inter-24-bold">
          Mood
          <br /> Tracker
        </div>
        <div className="flex flex-col flex-1 items-center">
          <PieChart
            data={formattedData}
            className="pie"
            // segmentsStyle={{
            //   borderColor: "black",
            // }}
          />

          <div className=" flex flex-row items-center ml-[30%] mr-auto mt-1">
            <div
              className="mood-round-btn "
              style={{
                backgroundColor: "#E8A4E0",
              }}
            ></div>
            <div className="inter-regular-12 text-left w-20 ml-3">Good</div>
          </div>
          <div className=" flex flex-row items-center ml-[30%] mr-auto mt-1">
            <div
              className="mood-round-btn "
              style={{
                backgroundColor: "#ECC542",
              }}
            ></div>
            <div className="inter-regular-12 text-left w-20 ml-3">Difficult</div>
          </div>
          <div className=" flex flex-row items-center ml-[30%] mr-auto mt-1">
            <div
              className="mood-round-btn "
              style={{
                backgroundColor: "#69E2E3",
              }}
            ></div>
            <div className="inter-regular-12 text-left w-20 ml-3">In-Between</div>
          </div>
          <div className=" flex flex-row items-center ml-[30%] mr-auto mt-1">
            <div
              className="mood-round-btn "
              style={{
                backgroundColor: "#F1F232",
              }}
            ></div>
            <div className="inter-regular-12 text-left w-20 ml-3">Anxious</div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="ModalContainer z-20">
      {visible && (
        <div className="ModalOverlay" onClick={handleBackdropClick}>
          <div className="modal-mood-content flex flex-1">
            <div className="ModalBody relative flex flex-1 flex-col">
              <div
                className="absolute right-1 top-2  flex flex-row justify-end mx-2"
                onClick={() => {
                  setVisible(false);
                }}
              >
                <img
                  className="h-6 w-6"
                  alt=""
                  src={require("../../assets/close.png")}
                />
              </div>
              <div className="inter-24-bold">November 5th-18th</div>
              {renderMoodRingCard()}
              <div className=" flex flex-1 grid grid-cols-2 gap-4 mx-3">
                {renderColumOne()}
                {renderColumTwo()}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MoodRingModal;
