import { useNavigate } from "react-router-dom";
import HomeScrollPicFragment from "../../components/dashboard/HomeScrollPicFragment";
import UserFeedCard from "../../components/dashboard/UserFeedCard";
import { AppPaths } from "../../utils/AppPaths";

const HalloweenChallenge = () => {
  const navigation = useNavigate();
  return (
    <div className="flex flex-col relative pb-20 mx-1 pt-3">
      <div className="flex flex-1 flex-row mx-3 items-center">
        <img
          onClick={() => {
            console.log("asdsdf");

            navigation(AppPaths.HOME);
          }}
          alt=""
          className="home-frag-pic-backbtn mr-2"
          src={require("../../assets/arrow-left.png")}
        />
        <div className="btn-onb2-text">Halloween-E Challenge</div>
      </div>
      <div className="py-5 mx-auto flex flex-col ">
        <HomeScrollPicFragment
          hideOptions={true}
          imgStyle={"challange-frag-img"}
          contStyle={`overflow-hidden challange-frag-img-cont`}
        />
      </div>
      <div className="inter-20-bold mx-auto">Amethyst House Responses</div>
      <div className="flex flex-1 flex-row items-center mx-3">
        <span className="bg-black h-0.5 flex flex-1"></span>
        <span className="flex flex-row items-center mx-2">
          <img
            src={require("../../assets/header_jem.png")}
            alt=""
            className="header-jem-sm mr-1"
          />
          <div className="inter-14-bold ">30&nbsp;pts</div>
        </span>
        <span className="bg-black h-0.5 flex flex-1"></span>
      </div>
      <div className=" flex flex-1 flex-col">
        <UserFeedCard type="img" />
        <UserFeedCard type="img" />
        <UserFeedCard type="img" />
        <UserFeedCard type="img" />
        <UserFeedCard type="img" />
      </div>
    </div>
  );
};

export default HalloweenChallenge;
