import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import HomeScreen from "../container/dashboard/HomeScreen";
import { AppPaths } from "../utils/AppPaths";
import Dashboard from "../container/dashboard/Dashboard";
import HalloweenChallenge from "../container/dashboard/HalloweenChallange";
import FallFunQuizChallange from "../container/dashboard/FallFunQuizChallange";
import DanceChallange from "../container/dashboard/DanceChallange";
import TimeQuiz from "../components/dashboard/TimeQuiz";
import MyJourney from "../components/myjourney/MyJourney";
import ProfileDetails from "../container/dashboard/profile/ProfileDetails";
import EditUserName from "../container/dashboard/profile/EditUserName";
import EditPassword from "../container/dashboard/profile/EditPassword";
import EditLocation from "../container/dashboard/profile/EditLocation";
import EditBirthday from "../container/dashboard/profile/EditBirthday";
import EditGrade from "../container/dashboard/profile/EditGrade";
import TechnicalSupport from "../container/dashboard/profile/TechnicalSupport";
import MoodRing from "../container/dashboard/moodring/MoodRing";
import ChatList from "../container/dashboard/chat/ChatList";
import Chat from "../container/dashboard/chat/Chat";

const RootRoutes = () => {
  return (
    <Routes>
      <Route path={AppPaths.DASHBOARD} element={<Dashboard />}>
        <Route path={AppPaths.HOME} element={<HomeScreen />} />
        <Route
          path={AppPaths.HALLOWEEN_CHALLENGE}
          element={<HalloweenChallenge />}
        />
        <Route
          path={AppPaths.FALLFUN_QUIZ_CHALLENGE}
          element={<FallFunQuizChallange />}
        />
        <Route path={AppPaths.DANCE_CHALLENGE} element={<DanceChallange />} />
        <Route path={AppPaths.PROFILE} element={<ProfileDetails />} />
        <Route path={AppPaths.EDIT_USERNAME} element={<EditUserName />} />
        <Route path={AppPaths.EDIT_PASSWORD} element={<EditPassword />} />
        <Route path={AppPaths.EDIT_LOCATION} element={<EditLocation />} />
        <Route path={AppPaths.EDIT_BIRTHDAY} element={<EditBirthday />} />
        <Route path={AppPaths.EDIT_GRADE} element={<EditGrade />} />
        <Route path={AppPaths.MESSAGES} element={<ChatList />} />
        <Route path={AppPaths.MOODRING} element={<MoodRing />} />
        <Route
          path={AppPaths.TECHNICAL_SUPPORT}
          element={<TechnicalSupport />}
        />
        <Route path={AppPaths.MYJOURNEY} element={<MyJourney />} />
      </Route>
      <Route path={AppPaths.CHAT} element={<Chat />} />
      <Route path={AppPaths.TIME_QUIZ} element={<TimeQuiz />} />

      <Route path={"*"} element={<Navigate to={AppPaths.HOME} />} />
    </Routes>
  );
};

export default RootRoutes;
