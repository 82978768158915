import { JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, useEffect, useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "../../../components/SecondaryButton";
import MoodRingModal from "../../../components/moodring/MoodRingModal";
import ApiService from "../../../api/ApiServices";
import moment from "moment";
import { getImageUrl } from "../../../constants/reactionImages";
const MoodRing = () => {
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const [moodRingData, setMoodRingData] = useState<any>(null);
  const [currentData, setCurrentData] = useState<any>(null);
  const [dailyData, setDailyData] = useState<any>(null);
  const [currentPeriod, setCurrentPeriod] = useState<any>(null);
  const getMoodRingData = async () => {
    let month = new Date().getMonth()
    let monthString: string;

    if (month < 10) {
      monthString = "0" + month;
    } else {
      monthString = month.toString();
    }
    const response = await ApiService.getMoodRingData(monthString);
    setMoodRingData(response?.data);
    setCurrentData(response?.data?.pastMoodRings[0]?.viewSummary)
    setDailyData(response?.data?.pastMoodRings[0]?.dailyCheckIns)
    setCurrentPeriod(response?.data?.pastMoodRings[0]?.period)
  }


  useEffect(() => {
    getMoodRingData()
  }, [])

  const rendersvg1 = () => {
    return (
      <svg
        // width="339"
        // height="42"
        viewBox="0 0 339 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          zIndex: -1,
          top: 20,
          width: '100%',
        }}
      >
        <path
          d="M0 0C133.99 10.9207 209.129 17.1483 339 0V42C200.138 18.0268 125.936 17.8443 0 42V0Z"
          fill="url(#paint0_linear_1153_1949)"
          fill-opacity="0.8"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1153_1949"
            x1="23.841"
            y1="20.9333"
            x2="308.954"
            y2="20.9333"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E8A4E0" />
            <stop offset="0.197917" stop-color="#ECC542" />
            <stop offset="0.416667" stop-color="#F5F552" />
            <stop offset="0.578125" stop-color="#B6EC80" />
            <stop offset="0.75" stop-color="#69E2E3" />
            <stop offset="0.979167" stop-color="#C0A4EE" />
          </linearGradient>
        </defs>
      </svg>
    );
  };
  const renderCardImage = () => {
    return (
      <div className="z-10 flex justify-center">
        <img
          alt=""
          className="moodring-card-image"
          src={require("../../../assets/moodring.png")}
        />
      </div>
    );
  };
  const renderMoodRingCard = () => {
    return (
      <div className="moodring-card px-2 py-2 relative z-20 mt-3">
        {rendersvg1()}
        {renderCardImage()}
        <div className="flex items-center flex-col">
          <div className="title-text-16">{moment(currentPeriod?.startDate).format('MMMM')} {moment(currentPeriod?.startDate).format('DD')}th-{moment(currentPeriod?.endDate).format('DD')}th</div>
          <div className="inter-regular-14 text-center">
            Your positive outlook will take you anywhere
            <br />
            you want to go.
          </div>

          <SecondaryButton
            text="View Summary"
            containerStyles="px-3 py-2 mt-2"
            textStyle="inter-text-14-semibold"
            onClick={() => {
              setVisible(true);
            }}
          />
        </div>
      </div>
    );
  };

  const renderCardDetails = () => {
    return (
      <div className="flex flex-col mt-3">
        <div className="flex flex-row inter-semibold-20 justify-center my-3">
        {moment(currentPeriod?.startDate).format('MMMM')}
        </div>
        {dailyData?.length === 0 ? <div className="text-center">No Daily Check Ins</div> : <div className="grid grid-cols-6 gap-1 mx-auto">
          {dailyData?.map((item: any, index: Key | null | undefined) => {
            return (
              <div
                key={index}
                className="flex flex-col items-center light-purple-bg px-3 py-2 mood-imoji-cont"
              >
                <div className="inter-12-bold">{moment(item?.date).format('DD')}</div>
                <img
                  alt=""
                  className="mood-ring-imoji my-2"
                  src={getImageUrl(item?.todayFeeling) || require("../../../assets/reaction/happy.png")}
                />
                <img
                  alt=""
                  className="mood-ring-zzz"
                  src={require("../../../assets/moodringZZZ.png")}
                />
                <div className="inter-regular-12">7.8 hrs</div>
              </div>
            );
          })}
        </div>}
      </div>
    );
  };

  const renderCardList = () => {
    return (
      <div className="flex flex-col mt-3">
        <div className="absolute">
          <MoodRingModal setVisible={setVisible} visible={visible} data={currentData} />
        </div>
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row inter-semibold-20  my-2">
            Past mood rings
          </div>
          <img
            alt=""
            className="home-frag-pic-cal mr-2"
            src={require("../../../assets/filter_list.png")}
          />
        </div>
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row inter-text-18-semibold">{new Date().getFullYear()}</div>
        </div>
        <div className="flex flex-col">
          {moodRingData?.pastMoodRings?.map((value: {
            [x: string]: any;
            averageSleepDurationHours: ReactNode; totalDaysCheckedIn: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; 
}) => {
            return (
              <div className="mood-list-cont flex flex-row py-3 px-3 mt-2" onClick={() => {
                setCurrentData(value?.viewSummary)
                setDailyData(value?.dailyCheckIns)
                setCurrentPeriod(value?.period)
              }}>
                <div className="flex flex-1 items-center">
                  <img
                    alt=""
                    className="list-jem"
                    src={require("../../../assets/moodring.png")}
                  />
                  <div className="title-text-16 ml-2">{moment(value?.period?.startDate).format('DD/MM')}-{moment(value?.period?.endDate).format('DD/MM')}</div>
                </div>
                <div className="flex flex-1 items-center">
                  <div className="flex flex-1 flex-col items-center">
                    <img
                      alt=""
                      className="mood-ring-zzz"
                      src={require("../../../assets/moodringZZZ.png")}
                    />
                    <div className="inter-text-12-semibold text-center">
                      {value?.averageSleepDurationHours} hrs
                      <br />
                      avg. sleep
                    </div>
                  </div>
                  <div className="flex flex-1 flex-col items-center">
                    <img
                      alt=""
                      className="mood-ring-zzz"
                      src={require("../../../assets/moodring_check.png")}
                    />
                    <div className="inter-text-12-semibold text-center">
                      {value?.totalDaysCheckedIn}/14 days <br />
                      tracked
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-1 flex-col relative pb-20 mx-1 pt-3 mb-5">
      <div className="flex flex-row mx-3 items-center">
        <div>
          <img
            onClick={() => {
              navigate(-1);
            }}
            alt=""
            className="home-frag-pic-backbtn mr-2"
            src={require("../../../assets/arrow-left.png")}
          />
        </div>
        <div className="btn-onb2-text flex flex-1 items-center justify-center">
          Mood Ring
        </div>
        <div>
          <img
            onClick={() => {
              navigate(-1);
            }}
            alt=""
            className="home-frag-pic-cal mr-2"
            src={require("../../../assets/calendar_today.png")}
          />
        </div>
      </div>
      <div className="flex flex-col flex-1 mx-3 overflow-y-auto">
        {renderMoodRingCard()}
        {renderCardDetails()}
        {renderCardList()}
      </div>
    </div>
  );
};

export default MoodRing;
