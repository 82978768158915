import { useNavigate } from "react-router";
import { AppPaths } from "../../../utils/AppPaths";
import { useAppDispatch } from "../../../redux/store";
import { useEffect, useState } from "react";
import { setActiveTab } from "../../../redux/rootSlice";
import ApiService from "../../../api/ApiServices";

const ChatList = () => {
  const [messages, setMessages] = useState<any>(null);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setActiveTab(2));
  }, [dispatch]);

  const getMessages = async () => {
    const response = await ApiService.getMessages();
    console.log('response', response?.data);
    setMessages(response?.data);
  }

  useEffect(() => {
    getMessages();
  }, [])

  const renderHeader = () => {
    return (
      <div className="flex flex-row items-center">
        <div>
          <img
            onClick={() => {
              navigate(-1);
            }}
            alt=""
            className="home-frag-pic-backbtn mr-2"
            src={require("../../../assets/arrow-left.png")}
          />
        </div>
        <div className="btn-onb2-text flex flex-1 items-center justify-center">
          Messages
        </div>
        <div>
          <img
            onClick={() => {
              navigate(-1);
            }}
            alt=""
            className="home-frag-pic-cal mr-2"
            src={require("../../../assets/edit_pink.png")}
          />
        </div>
      </div>
    );
  };

  const renderProfile = () => {
    return (
      <div className="flex flex-1 items-center justify-center ">
        <div className="chat-icon-cont px-7 py-5 relative">
          <img
            src="https://picsum.photos/200/300"
            alt=""
            className="chat-profile-item "
          />
        </div>
      </div>
    );
  };

  const renderTechnicalSupportProfile = () => {
    return (
      <div className="flex flex-1 items-center justify-center ">
        <div className="chat-icon-cont px-7 py-5 relative">
          <img
            src={require("../../../assets/Item1.png")}
            alt=""
            className="technical-support-item"
          />
        </div>
      </div>
    );
  }

  const renderListItem = (item: any) => {
    return (
      <div
        className="flex flex-row mb-3"
        onClick={() => {
          navigate(AppPaths.CHAT);
        }}
      >
        <div>{renderProfile()}</div>
        <div className="flex flex-col ml-3">
          <div className="inter-text-20-extrabold">{item?.name}</div>
          <div className="inter-regular-14">{item?.role}</div>
          <div
            className="inter-regular-16 flex flex-row flex-wrap chat-message"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {item?.message}
          </div>
        </div>
      </div>
    );
  };

  const renderTechnicalSupportItem = (item: any) => {
    return (
      <div
        className="flex flex-row mb-3"
        onClick={() => {
          navigate(AppPaths.TECHNICAL_SUPPORT);
        }}
        style={{ alignItems: 'center' }}
      >
        <div>{renderTechnicalSupportProfile()}</div>
        <div className="flex flex-col ml-3">
          <div className="inter-text-20-extrabold">{item?.name}</div>
          <div className="inter-regular-14">Live chat</div>
          {/* <div
            className="inter-regular-16 flex flex-row flex-wrap chat-message"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            Where are you from? What's your favorite color?!
          </div> */}
        </div>
      </div>
    );
  };
  return (
    <div className="flex flex-1 flex-col relative pt-3 mx-3">
      {renderHeader()}
      <div className="inter-text-20-extrabold my-3">Contacts</div>
      <div className="flex flex-col flex-1">
        {renderListItem(messages?.bigSister)}
        {renderListItem(messages?.houseLead)}
        {/* {renderListItem()} */}
        {messages?.technicalSupports?.map((item: any) => {
          return renderTechnicalSupportItem(item)
        })}
      </div>
    </div>
  );
};
export default ChatList;
