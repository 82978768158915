import { useSpring, animated } from "@react-spring/web";
import { useEffect, useState } from "react";
import PrimaryButton from "../PrimaryButton";
import { useNavigate } from "react-router-dom";

const TimeQuiz = () => {
  const [isPopped, setIsPopped] = useState<boolean>(false);
  const [isChecked, setChecked] = useState<number>(-1);
  const navigate = useNavigate();
  const popAnimation = useSpring({
    transform: isPopped ? "scale(1)" : "scale(0.5)",
    config: {
      duration: 100,
    },
  });

  const springProps1 = useSpring({
    from: { marginRight: "100%" },
    to: { marginRight: "0%" },
    // loop: { reverse: true },
    config: { duration: 200 },
  });

  useEffect(() => {
    setIsPopped(true);
  }, []);

  const renderTopSvg = () => {
    return (
      <svg
        // width="100%"
        // height="210"
        viewBox="0 0 375 454"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "absolute",
          top: -40,
          zIndex: -1,
          // maxHeight: 320,
          // left: 0,
          // right: 0,
        }}
      >
        <path
          d="M-64 125L27.3314 101.816C137.894 73.7501 253.837 74.7737 363.886 104.787L438 125"
          stroke="#FFD3F6"
          stroke-width="213"
        />
      </svg>
    );
  };
  const renderHeaderImage = () => {
    return (
      <span className=" flex flex-1 justify-center relative">
        <animated.img
          style={{ ...popAnimation }}
          alt=""
          src={require("../../assets/Item1.png")}
          className="onb1-2 inline-block mr-[-10px]"
        />
        <animated.div className="" style={{ ...springProps1 }}>
          <img
            alt=""
            src={require("../../assets/time_quiz.png")}
            className="onb1-2-m1 "
          />
        </animated.div>
      </span>
    );
  };

  const options = [
    "Forget about it. Everyone looks bad in yearbook photos!",
    "Ask the yearbook editor to airbrush my photo.",
    "Refuse to submit my photo. It doesn’t do me justice!",
  ];

  return (
    <div className="flex flex-col h-svh relative  z-10">
      <div
        className="absolute right-1 top-2  flex flex-row justify-end px-2 py-1"
        onClick={() => {
          navigate(-1);
        }}
      >
        <img
          className="h-8 w-8"
          alt=""
          src={require("../../assets/close.png")}
        />
      </div>
      {renderTopSvg()}
      <div className="z-11">
        <div className="mt-10 mx-3">{renderHeaderImage()}</div>
      </div>
      <div className="flex flex-col  flex-1 mt-5 py-8 mx-3 overflow-y-auto">
        <div className="flex  flex-row justify-between  items-baseline">
          <div className="sora-semibold-24">Fall Fun Quiz</div>
          <div className="sora-semibold-14">2&nbsp;mins</div>
        </div>
        <div className="sora-regular-16 text-justify">
          You just got your yearbook photos back from the photographer, and you
          don’t like any of them! It’s too late for retakes. What do you do?
        </div>
        <div className="mt-3">
          {options?.map((option, index) => (
            <div className="option-cont flex flex-row px-3 py-3 mt-3" onClick={() => setChecked(index)}>
              <label className={`checkbox-container`}>
                <input
                  type="checkbox"
                  checked={isChecked === index}
                  onChange={() => {
                    setChecked(index);
                  }}
                />
                <span
                  className={`checkmark checkmark-round  ${isChecked === index && `checkmark-checked`
                    }`}
                ></span>
              </label>
              <div className="sora-regular-16 text-justify">{option}</div>
            </div>
          ))}
        </div>
      </div>
      <PrimaryButton
        text={"NEXT"}
        onClick={() => { }}
        containerStyles="py-3 mt-3 mx-2 mb-3"
        textStyle="primary-btn-text"
        disable={isChecked < 0}
      />
    </div>
  );
};

export default TimeQuiz;
